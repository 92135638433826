<template>
    <div class="password-reset">

        <div class="info-block">
            <!-- <h2>{{ $t(`Reset Password`) }}</h2> -->
            <form @submit.prevent="resetPassword">
                <div class="input-group">
                    <!-- <label for="current-password">{{ $t(`Current password`) }}</label> -->
                    <input
                        type="password"
                        id="current-password"
                        v-model="passwordData.currentPassword"
                        :placeholder="$t('Current password')"
                    />
                </div>
                <div class="input-group">
                    <!-- <label for="new-password">{{ $t(`New password`) }}</label> -->
                    <input
                        type="password"
                        id="new-password"
                        v-model="passwordData.newPassword"
                        :placeholder="$t('New password')"
                    />
                </div>
                <div class="input-group">
                    <!-- <label for="confirm-password">{{ $t(`Confirm new password`) }}</label> -->
                    <input
                        type="password"
                        id="confirm-password"
                        v-model="passwordData.confirmPassword"
                        :placeholder="$t('Confirm new password')"
                    />
                </div>
                <div class="button-wrapper">
                    <BaseButton
                        text="CHANGE"
                        buttonClass="change-button"
                        type="submit"
                    />
                </div>

                <!-- Display the error message from API here -->
                <p
                    v-if="errorMessage"
                    class="error-message"
                >
                    {{ errorMessage }}
                </p>

                <!-- Display the success message when password is changed -->
                <p
                    v-if="successMessage"
                    class="success-message"
                >
                    {{ successMessage }}
                </p>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PasswordReset',
    data() {
        return {
            passwordData: {
                currentPassword: '',
                newPassword: '',
                confirmPassword: ''
            },
            errorMessage: '', // Store error messages from API
            successMessage: '' // Store success messages
        };
    },
    methods: {
        async resetPassword() {
            this.errorMessage = ''; // Clear previous errors
            this.successMessage = ''; // Clear success message

            try {
                const response = await this.$http.post(process.env.VUE_APP_API + 'user/change-password', {
                    cur_password: this.passwordData.currentPassword,
                    new_password: this.passwordData.newPassword,
                    repeat_password: this.passwordData.confirmPassword
                });

                // If the API response status is OK, display success
                if (response.data.status === 'OK') {
                    this.successMessage = 'Password changed successfully!';
                    this.clearForm();
                }
            } catch (error) {
                if (error.response && error.response.data) {
                    // Capture and display the API error message directly
                    this.errorMessage = error.response.data.message || 'An unexpected error occurred.';
                }
            }
        },
        clearForm() {
            this.passwordData.currentPassword = '';
            this.passwordData.newPassword = '';
            this.passwordData.confirmPassword = '';
        }
    }
};
</script>

<style scoped>
.password-reset {
    padding: 52px;
}

.info-block {
    max-width: 400px;
    margin: 0 auto;
    padding: 42px;
    background-color: #262634;
    border-radius: 2px;
}

h2 {
    color: white;
    font-family: Roboto, sans-serif;
    margin-bottom: 20px;
}

.input-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
    color: #b7b8c1;
    font-family: Roboto, sans-serif;
}

.error-message {
    color: #ff6464;
    font-size: 12px;
    margin-top: 5px;
}

.success-message {
    color: #6aff64;
    margin-top: 20px;
    text-align: center;
}

.button-wrapper {
    width: 160px;
    height: 48px;
    margin-left: auto;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}


@media (max-width: 768px) {
    .password-reset {
        padding: 52px 12px 52px;
    }

    .button-wrapper {
        margin: 0 auto;
    }
}
</style>