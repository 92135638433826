<template>
    <section class="we-believe section">
        <div class="wrapper">
            <div class="commited-block">
                <div class="flex">

                    <div class="img-container">
                        <img
                            src="@/assets/img/we-believe.png"
                            class="img"
                            alt="we believe img"
                        >
                    </div>
                    <div class="text">
                        <div class="title normal-weight">{{ $t(`We believe in making customization accessible to every player.`) }}</div>
                        <div class="desc small gray">
                            {{ $t(`That’s why we regularly offer CS2 skin discounts and the best CS2 skin prices, giving you access to top-quality designs at affordable rates. Our Counter-Strike 2 weapon skins let you showcase your unique style and make every match feel fresh and exciting.`) }}
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
</template>


<script>
export default {
    name: 'WeBelieve',
    data() {
        return {
        }
    }
}
</script>


<style lang="scss" scoped>
.we-believe {
    position: relative;

    .wrapper {
        max-width: 1020px;
        padding-bottom: 80px;
        padding-top: 80px;
    }
    .commited-block {
        
        .flex {
            align-items: center;
            gap: 124;
        }
        .text {
            flex: 1;
        }
        .img-container {
            flex: 1;
        }
        .desc {
            margin-top: 40px;
            max-width: 428px;
        }
    }
}
@media screen and (max-width: 768px) {
    .we-believe .commited-block {
        text-align: center;

        .desc {
            margin: 40px auto;
        }
        .img-container {
            display: none;
        }
    }
}
</style>