<template>
  <div class="modal login-modal">
    <div
      class="overlay"
      @click="$emit('closeSignIn')"
    />
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <img
              class="close"
              src="./../assets/img/close.svg"
              @click="$emit('closeSignIn')"
            />
            <div class="modal-main">
              <div :class="[
                'form-fields login-content',
                { hidden: !loginContentVisible },
              ]">
                <div class="form-fields-wrapper">
                  <div class="modal-title">
                    {{ $t("Sign In") }}
                  </div>
                  <label>
                    <input
                      v-model="email"
                      type="email"
                      :placeholder="$t('Email')"
                      @keyup.enter="submitLogin"
                      autocomplete="username"
                    />
                  </label>
                  <label>
                    <input
                      v-model="pass"
                      type="password"
                      :placeholder="$t('Password')"
                      @keyup.enter="submitLogin"
                    />
                  </label>
                  <div class="link-container">
                    <a
                      class="desc link"
                      @click="switchToforgotPass"
                    >
                      {{ $t("Forgot password?") }}
                    </a>
                  </div>

                  <button
                    class="modal-sign-in-button"
                    @click="submitLogin"
                  >
                    <div
                      v-if="loaderIsVisible"
                      class="loader-container"
                    >
                      <div class="loader"></div>
                    </div>
                    <span v-if="!loaderIsVisible">{{ $t("Sign In") }}</span>
                  </button>
                  <Transition>
                    <div
                      v-if="error"
                      class="error-desc desc red"
                    >
                      {{ error }}
                    </div>
                  </Transition>
                  <Transition>
                    <div
                      v-if="showResendOption"
                      class="resend-option"
                    >
                      <button
                        class="resend-button button blue"
                        @click="resendVerificationEmail"
                      >
                        {{ $t("Resend Verification Email") }}
                      </button>
                    </div>
                  </Transition>
                </div>
              </div>
              <div :class="[
                'form-fields forgot-pass-content',
                { hidden: !forgotPassContentVisible },
              ]">
                <div class="form-fields-wrapper">
                  <div class="modal-title">
                    {{ $t("Forgot password?") }}
                  </div>
                  <label>
                    <input
                      v-model="email"
                      type="email"
                      :placeholder="$t('Email')"
                      autocomplete="username"
                    />
                  </label>
                  <div class="cta-container">
                    <button
                      class="modal-recover-password-button"
                      @click="submitForgotPass"
                    >
                      <div
                        v-if="loaderIsVisible"
                        class="loader-container"
                      >
                        <div class="loader"></div>
                      </div>
                      <span v-if="!loaderIsVisible">{{ $t("Recover") }}</span>
                    </button>
                  </div>
                  <Transition>
                    <div
                      v-if="successMessage"
                      class="desc green"
                    >
                      {{ successMessage }}
                    </div>
                  </Transition>
                  <Transition>
                    <div
                      v-if="error"
                      class="error-desc desc red"
                    >
                      {{ error }}
                    </div>
                  </Transition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-bottom">
        <div class="modal-bottom-content">
          <div class="desc">
            {{ forgotPassContentVisible ? $t("Have account?") : $t("Don't have an account?") }}
          </div>
          <a
            class="modal-bottom-sign-up-button"
            @click="forgotPassContentVisible ? switchToLogin() : openSignUp()"
          >
            {{ forgotPassContentVisible ? $t("Sign in") : $t("Sign up") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LoginModal",
  props: {
    error: {
      type: String,
      required: true
    },
    successMessage: {
      type: String,
      required: true
    },
    loaderIsVisible: {
      type: Boolean,
      required: true
    },
    showResendOption: {
      type: Boolean,
      required: true
    },
  },
  data: function () {
    return {
      loginContentVisible: true,
      forgotPassContentVisible: false,
      email: "",
      pass: "",
    };
  },
  mounted() {
  },
  methods: {
    resendVerificationEmail() {
      this.$emit('resendVerificationEmail', this.email);
    },
    openSignUp() {
      this.$emit("clearError");
      this.$emit('openSignUp');
    },
    switchToforgotPass() {
      this.$emit('hideResendOption');
      this.$emit("clearError");
      setTimeout(() => {
        this.loginContentVisible = false;
        this.forgotPassContentVisible = true;
      }, 100);
    },
    switchToLogin() {
      this.$emit('hideResendOption');
      this.$emit("clearError");
      setTimeout(() => {
        this.forgotPassContentVisible = false;
        this.loginContentVisible = true;
      }, 100);
    },
    submitLogin() {
      let loginData = {
        username: this.email,
        password: this.pass,
      };
      this.$emit("login", loginData);
    },
    submitForgotPass() {
      let data = {
        email: this.email,
      };
      this.$emit("forgotPass", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {}

.modal .wrapper {}

.modal .wrapper .container {
  background: #161C2A;
  border-radius: 12px;
  padding: 44px 8px 33px;
  margin-bottom: 12px;
}

.modal .form-fields {
  max-width: 298px;
  margin: 0 auto;
}

.modal .form-fields label {
  margin-top: 38px;
}

.modal .form-fields label+label {
  margin-top: 14px;
}


.modal-sign-in-button,
.modal-recover-password-button {
  margin-bottom: 33px;
}

.modal-recover-password-button {
  margin-top: 24px;
}

.modal-bottom {
  padding: 26px 24px;
  background: linear-gradient(180deg, rgba(255, 230, 68, 0.1) 0%, rgba(0, 179, 48, 0.1) 100%);
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);

  &::before {
    content: '';
    position: absolute;
    inset: -1px;
    z-index: -1;
    padding: 2px;
    border-radius: 14px;
    background-image: linear-gradient(180deg, #FFE644 0%, #00B230 100%);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
  }

  .modal-bottom-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .desc {
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
    }

    .link {
      color: #00B230;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.resend-button {
  width: 100%;
  margin-top: 16px;
}
</style>