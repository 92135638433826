<!-- File: src/components/CookiesModal.vue -->
<template>
    <div
        v-if="isVisible"
        class="modal-overlay"
    >
        <div class="cookies-modal">
            <div class="title">
                {{ $t('About Cookies') }}
            </div>
            <div class="desc">
                {{
                    $te('This Website stores cookies on your computer')
                        ? $t('This Website stores cookies on your computer')
                        : `This Website stores cookies on your computer. These cookies collect information about how you
                navigate our Website and allow us to remember you. We use this information to improve and customise your
                browsing experience and for analytics and metrics about our visitors on this Website.`
                }}
                <br />
                {{
                    $te('Before you proceed with using our Website')
                        ? $t('Before you proceed with using our Website')
                        : `Before you proceed with using our Website, please get acquainted with the way we handle a User’s data
                in our Privacy Policy and the use of Cookies in the Cookies Policy.`
                }}
                <br />
                {{
                    $te('You can either accept or decline use of Cookies')
                        ? $t('You can either accept or decline use of Cookies')
                        : `You can either accept or decline use of Cookies. If you opt to decline Cookies your information won’t
                be tracked when you visit this Website.`
                }}
                <a
                    class="desc blue"
                    @click="goToPage('cookie')"
                >
                    {{ $t('Cookie Policy') }}
                </a>.
            </div>
            <button
                class="button blue"
                @click="acceptCookies"
            >
                {{ $t('Accept') }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CookiesModal',
    data() {
        return {
            isVisible: false
        };
    },
    methods: {
        showModal() {
            const cookiesAccepted = localStorage.getItem("cookiesAccepted");
            if (!cookiesAccepted) {
                this.isVisible = true;
            }
        },
        acceptCookies() {
            localStorage.setItem("cookiesAccepted", "true");
            this.isVisible = false;
        },
        goToPage(page) {
            this.$emit('navigate', page);
        }
    },
    mounted() {
        this.showModal();
    }
};
</script>


<style lang="scss" scoped>
.cookies-modal {
    background-color: #161C2A;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 500px;
    margin: 40px auto 0;
    border-radius: 8px;
}

.cookies-modal .button {
    margin: 16px 8px;
    width: 120px;
    display: inline-block;
}
</style>