// store/modules/cart.js (Vuex module)
import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    items: [],
    cartContents: [],
  },
  mutations: {
    SET_CART_ITEMS(state, items) {
      state.items = items;
    },
    CLEAR_CART(state) {
      state.items = [];
    },
    UPDATE_CART_CONTENTS(state, cart) {
      state.cartContents = cart;
    },
  },
  actions: {
    // Fetch cart items from the server
    fetchCartItems({ commit, rootGetters }) {
      const currencyCode = rootGetters["app/currencyCode"];

      return axios
        .get(`${process.env.VUE_APP_API}cart/contents?currency=${currencyCode}`)
        .then((response) => {
          commit("SET_CART_ITEMS", response.data.cart);
        })
        .catch((error) => {
          console.error("Error fetching cart:", error);
        });
    },

    // Action to remove item from cart
    removeFromCart({ dispatch }, item) {
      const cartItem = { id: item.id };

      // Return a promise so chaining is possible
      return axios
        .post(`${process.env.VUE_APP_API}cart/remove`, cartItem)
        .then(() => {
          // After removing, refresh the cart items
          return dispatch("fetchCartItems");
        })
        .catch((error) => {
          console.error("Error removing item from cart:", error);
        });
    },
    updateCartContents({ commit }, cart) {
      commit("UPDATE_CART_CONTENTS", cart);
    },
  },
  getters: {
    cartContents: (state) => state.items,
  },
};
