<template>
    <div class="product-page">
        <Breadcrumbs :crumbs="generateBreadcrumbs()" />

        <div
            class="product-details-container"
            v-if="product"
        >
            <!-- Left Image Container -->
            <div class="product-image-container">
                <img
                    :src="product?.image_url || (imgDomain + product?.img_url)"
                    :alt="product?.name || product?.title"
                    class="product-image"
                />
                <img
                    src="@/assets/img/product-page-left-arrow.png"
                    class="left-arrow"
                    alt="left arrow"
                />
                <img
                    src="@/assets/img/product-page-right-arrow.png"
                    class="right-arrow"
                    alt="right arrow"
                />
                <div
                    v-if="product?.id === chosenItem"
                    class="added-inscription"
                >
                    {{ $t(`Added`) }}
                </div>
            </div>

            <!-- Right Side Block (Product Info) -->
            <div class="product-info-wrapper">
                <div class="product-type-tag">
                    {{ product?.type }}
                </div>
                <h1 class="product-title">
                    {{ product?.name || product?.title }}
                </h1>
                <div class="product-quality-tag">
                    {{ product?.exterior }}
                </div>
                <div class="product-price">
                    {{ product.price }} {{ currencySymbol }}
                </div>
                <div class="add-to-cart-button-wrapper">
                    <button
                        type="button"
                        class="button add-to-cart-button"
                        @click="addToCart(product)"
                    >
                        <span>{{ $t(`ADD TO CART`) }}</span>
                    </button>
                </div>
            </div>
        </div>

        <div
            v-else-if="!product && !productLoading"
            class="no-product"
        >
            {{ $t('Product not found') }}
        </div>

        <div class="product-page-carousel">
            <ProductCarousel
                v-if="product?.similar && product.similar.length > 0"
                :itemList="product.similar"
                title="Recommend"
                showArrows="true"
                @goToProduct="goToProduct"
                :background="'none'"
                :initialProducts="product?.similar"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductCarousel from '@/components/ProductCarousel.vue';
import Breadcrumbs from './Breadcrumbs.vue';

export default {
    name: 'ProductPage',
    components: {
        ProductCarousel,
        Breadcrumbs,
    },
    props: {
        id: {
            type: String,
        },
        chosenItem: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            product: null,
            imgDomain: '',
            productLoading: true,
        };
    },
    computed: {
        ...mapGetters('app', ['currencyCode', 'currencySymbol']),
        ...mapGetters('cart', ['cartContents']),
    },
    created() {
        this.fetchProductData();
    },
    mounted() {
        this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    },
    watch: {
        id() {
            this.fetchProductData();
        },
        currencyCode() {
            this.fetchProductData();
        },
    },
    methods: {
        generateBreadcrumbs() {
            if (!this.product) return [
                {
                    text: 'CS 2',
                    link: `/products/all`,
                }
            ];

            return [
                {
                    text: 'CS 2',
                    link: `/products/all`,
                },
                {
                    text: this.product.type,
                    link: `/products/${this.product.type}`,
                },
                {
                    text: this.product.title,
                    link: `/product/${this.product.id}`,
                },
            ];
        },
        addToCart(item) {
            const itemAlreadyInCart = this.cartContents.some(cartItem => cartItem.item_id === item.id);

            if (itemAlreadyInCart) {
                this.$toast.info("This item is already in your cart.");
                return;
            }
            this.$emit('addToCart', item);
        },
        fetchProductData() {
            this.productLoading = true;
            const apiEndpoint = `items/${this.id}`;
            this.$http.get(`${process.env.VUE_APP_API}${apiEndpoint}`, {
                params: {
                    currency: this.currencyCode,
                    include_price_history: true
                }
            })
                .then((res) => {
                    this.product = res.data.payload;
                })
                .catch((error) => {
                    console.error('Failed to load product data:', error);
                })
                .finally(() => {
                    this.productLoading = false;
                });
        },
        goToProduct(item) {
            this.$emit('goToProduct', item);
        },
    },
};
</script>

<style scoped lang="scss">
.product-page {
    flex: 1;
}

.product-details-container {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    margin: 0px auto 80px;
    max-width: 1240px;
    gap: 16px;
}

/* Left Image Container */
.product-image-container {
    padding: 40px;
    position: relative;
    width: 709px;
    background: #FFFFFF0D;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-image {
    width: 410px;
    height: 309px;
    object-fit: contain;
    z-index: 1;
}

.left-arrow,
.right-arrow {
    pointer-events: none;
    user-select: none;
    z-index: 0;
}

.left-arrow {
    position: absolute;
    left: 44px;
    bottom: 54px;
    cursor: pointer;
}

.right-arrow {
    position: absolute;
    right: 44px;
    top: 54px;
    cursor: pointer;
}

.added-inscription {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    font-weight: 700;
    font-size: 48px;
    z-index: 5;
}

/* Right Info Block */
.product-info-wrapper {
    width: 504px;
    background: #FFFFFF0D;
    padding: 46px 46px 77px;
    border-radius: 2px;
}

.product-type-tag {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #B8B8C0;
    margin-bottom: 22px;
}

.product-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
    color: white;
    margin-bottom: 16px;
}

.product-quality-tag {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #FFE416;
    margin-bottom: 32px;
}

.product-price {
    font-family: 'Roboto', sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
    color: white;
    margin-bottom: 33px;
}

.add-to-cart-button-wrapper {
    display: flex;
    // justify-content: flex-start;
    align-items: center;
}

.add-to-cart-button {
    max-width: 298px;
    // height: 46px;
    // background: radial-gradient(144.28% 89.13% at 50.34% 0%, #FFE416 0%, #00B330 100%);
    // box-shadow: 0px -4px 4px 0px #FFFFFF26 inset;
    // border: none;
    // border-radius: 8px;
    // cursor: pointer;
    // color: #000;
}

.no-product {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    font-size: 20px;
    color: white;
}

.product-page-carousel {
    width: 100%;
    margin-bottom: 16px;
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .product-details-container {
        flex-direction: column;
        align-items: center;
    }

    .product-image-container,
    .product-info-wrapper {
        width: 100%;
        max-width: 709px;
    }
}

@media (max-width: 768px) {

    .product-image-container {
        width: 100%;
    }

    .product-image {
        width: 100%;
        height: auto;
    }

    .product-title {
        font-size: 24px;
        line-height: 29px;
    }

    .product-price {
        font-size: 24px;
    }

    .add-to-cart-button {
        width: 250px;
        height: 40px;
        font-size: 12px;
    }

    .left-arrow,
    .right-arrow {
        opacity: 0.3;
    }

    .left-arrow {
        left: 20px;
        bottom: 20px;
    }

    .right-arrow {
        right: 20px;
        top: 20px;
    }
}
</style>
