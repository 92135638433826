<template>
    <div
        class="custom-dropdown"
        :class="{ 'is-open': isOpen, 'is-currency': isCurrency, 'is-filter': isFilter }"
    >
        <div
            class="dropdown-header"
            @click="toggleDropdown"
        >
            <slot :selectedOption="selectedOption">
                <span class="selected-option">{{ selectedOption || placeholder }}</span>
            </slot>
            <span
                class="arrow-down"
                :class="{ 'rotated': isOpen }"
            ></span>
        </div>
        <div
            v-if="isOpen"
            class="dropdown-content"
        >
            <div
                v-if="!isCurrency"
                class="multi-column"
            >
                <div
                    v-for="(column, columnIndex) in columns"
                    :key="columnIndex"
                    class="column"
                >
                    <div
                        v-for="option in column"
                        :key="option"
                        class="option"
                        @click="selectOption(option)"
                        :class="{ 'selected': option === selectedOption }"
                    >
                        {{ option }}
                    </div>
                </div>
            </div>
            <div
                v-else
                class="single-column"
            >
                <div
                    v-for="option in options"
                    :key="option"
                    class="option"
                    @click="selectOption(option)"
                    :class="{ 'selected': option === selectedOption }"
                >
                    {{ option }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CustomDropdown',
    props: {
        options: {
            type: Array,
            required: true
        },
        placeholder: {
            type: String,
            default: 'Select an option'
        },
        isCurrency: {
            type: Boolean,
            default: false
        },
        isFilter: {
            type: Boolean,
            default: false
        },
        initialValue: {
            type: String,
            default: null
        },
        showSelectedValue: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isOpen: false,
            selectedOption: this.initialValue
        }
    },
    computed: {
        columns() {
            if (this.isCurrency) return [this.options];
            const itemsPerColumn = Math.ceil(this.options.length / 2);
            return [
                this.options.slice(0, itemsPerColumn),
                this.options.slice(itemsPerColumn)
            ];
        }
    },
    watch: {
        // initialValue(newValue) {
        //     this.selectedOption = newValue;
        // }
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        selectOption(option) {
            if (this.showSelectedValue) {
                this.selectedOption = option;
            }
            this.$emit('option-selected', option);
            this.isOpen = false;
        },
        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.isOpen = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.custom-dropdown {
    position: relative;
    font-family: 'Open Sans', sans-serif;

    .dropdown-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 14px 16px;
        color: #fff;
        border-radius: 23px;
        cursor: pointer;
        gap: 4px;
        text-wrap: nowrap;
        transition: all 0.3s ease;
        position: relative;
        background: #212735;
        z-index: 0;

        .arrow-down {
            width: 12px;
            height: 12px;
            background-image: url('@/assets/img/arrow-down.svg');
            background-size: contain;
            background-repeat: no-repeat;
            transition: transform 0.3s ease;

            &.rotated {
                transform: rotate(180deg);
            }
        }
    }

    &.is-open .dropdown-header,
    &.is-filter.is-open .dropdown-header {
        background: transparent;

        &::before {
            content: '';
            position: absolute;
            inset: -1px;
            padding: 2px;
            border-radius: 23px;
            background: linear-gradient(180deg, #FFE416 0%, #00B330 100%);
            -webkit-mask:
                linear-gradient(#fff 0 0) content-box,
                linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
            mask:
                linear-gradient(#fff 0 0) content-box,
                linear-gradient(#fff 0 0);
            mask-composite: exclude;
            z-index: -1;
        }

        &::after {
            content: '';
            position: absolute;
            inset: 0;
            border-radius: 23px;
            background: rgba(0, 178, 48, 0.1);
            z-index: -2;
        }
    }

    &.is-filter.is-open .dropdown-header {
        &::before {
            border-radius: 12px;
        }
    }

    &.is-filter .dropdown-content {
        width: max-content;
    }

    &.is-currency .dropdown-header {
        width: 87px;
        height: 46px;
    }

    &.is-filter .dropdown-header {
        width: fit-content;
        height: auto;
        background: transparent;
        gap: 6px;
        padding: 11px 14px;
        border-radius: 12px;

        &:hover,
        &:focus {
            background: transparent;

            &::after {
                background: rgba(0, 178, 48, 0.1);
            }
        }

        .selected-option {
            text-transform: uppercase;
            font-weight: 700;
        }

    }

    &.is-filter {
        .option {
            text-transform: capitalize;
        }
    }

    .dropdown-content {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background: #09101F;
        border: 2px solid transparent;
        border-radius: 12px;
        background-image: linear-gradient(#09101F, #09101F),
            linear-gradient(180deg, #FFE644 0%, #00B230 100%);
        background-origin: border-box;
        background-clip: padding-box, border-box;
        padding: 20px 22px;
        margin-top: 11px;
        z-index: 10;

        .multi-column {
            display: flex;
            gap: 12px;

            .column {
                max-width: 138px;
            }
        }

        .option {
            font-size: 14px;
            font-weight: 400;
            line-height: 28px;
            color: white;
            cursor: pointer;

            &:hover {
                color: #6462FF;
            }
        }
    }

    &.is-currency .dropdown-content {
        .single-column {
            .option {
                padding: 5px 0;
            }
        }
    }
}
</style>