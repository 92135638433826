<template>
    <section class="start-shopping-section">
        <div class="wrapper">
            <div class="content">
                <div class="text-content">
                    <h2 class="section-title">
                        Start Shopping
                        <span class="bold-new-line">{{ $t(`Now!`) }}</span>
                    </h2>
                    <p class="section-description">{{ $t(`Discover the best CS2 skins and take your gameplay to the next
                        level. Whether you’re looking to upgrade your weapons or build a rare collection, our
                        marketplace is your go-to source for premium CS2 skins.`) }}</p>
                    <button
                        v-if="!isAuth"
                        @click="$emit('openSignUp')"
                        class="sign-in-button"
                    >
                        {{ $t(`Sign In`) }}
                    </button>
                    <router-link
                        v-else
                        :to="{ name: 'ProductListPage' }"
                        class="sign-in-button"
                    >
                        {{ $t('Buy now') }}
                    </router-link>
                </div>
                <img
                    src="@/assets/img/start-right.png"
                    alt="Start Shopping"
                    class="section-image"
                >
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'StartShopping',
    props: {
        isAuth: Boolean
    }
}
</script>

<style lang="scss" scoped>
.start-shopping-section {
    background: #09101F;
    padding: 50px 0;

    .wrapper {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 20px;
    }

    .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;

        @media (max-width: 768px) {

            flex-direction: column;
            text-align: center;
        }
    }

    .text-content {
        flex: 1;
    }

    .section-title {
        font-size: 52px;
        font-weight: 400;
        line-height: 1.2;
        color: white;
        margin-bottom: 22px;

        .bold-new-line {
            display: block;
            font-weight: 700;
        }

        @media (max-width: 768px) {
            font-size: 32px;
        }
    }

    .section-description {
        max-width: 413px;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 21.79px;
        color: #B8B8C0;
        margin-bottom: 30px;
    }

    .section-image {
        max-width: 50%;
        height: auto;

        @media (max-width: 768px) {
            max-width: 80%;
            margin: 0 auto;
            display: block;
        }
    }
}
</style>