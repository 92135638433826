<template>
    <div class="price-container">
        <div class="price-header">
            <div class="title price-label">{{ $t(`Price`) }}</div>
            <div class="price-range">

                <div class="from-value">
                    <div class="from">{{ $t(`from`) }}</div>
                    <div class="min-value">{{ minValue }} {{ currencySymbol }}</div>
                </div>

                <div class="to-value">
                    <div class="to">{{ $t(`To`) }}</div>
                    <div class="max-value">{{ maxValue }} {{ currencySymbol }}</div>
                </div>
            </div>
        </div>
        <div class="slider-wrapper">
            <MultiRangeSlider
                :baseClassName="'multi-range-slider'"
                :min="0"
                :max="maxLabelValue"
                :step="0.01"
                :ruler="false"
                :label="false"
                :minValue="minValue"
                :maxValue="maxValue"
                @input="updateValues"
            />
            <div class="slider-labels">
                <div class="min-label">0 {{ currencySymbol }}</div>
                <div class="max-label">{{ maxLabelValue }} {{ currencySymbol }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import MultiRangeSlider from "multi-range-slider-vue";
import { mapGetters } from "vuex";

export default {
    components: {
        MultiRangeSlider,
    },
    props: {
        minValue: {
            type: Number,
            default: 0,
        },
        maxValue: {
            type: Number,
            default: 9999,
        },
    },
    data() {
        return {
            maxLabelValue: 9999,
        };
    },
    computed: {
        ...mapGetters('app', ['currencySymbol']),
    },
    mounted() {
        this.maxLabelValue = this.maxValue;
    },
    watch: {
    },
    emits: ["update-range"],
    methods: {
        updateValues(e) {
            this.$emit('update-range', { min: e.minValue, max: e.maxValue });
        },
    },
};
</script>

<style lang="scss" scoped>
.price-container {
    font-family: 'Open Sans', sans-serif;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.price-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

}

.price-label {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
}

.from-value,
.to-value {
    display: flex;
    align-items: center;
    gap: 3px;
}

.price-range {
    display: flex;
    align-items: center;
    gap: 12px;

    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;
    color: white;
    text-wrap: nowrap;
}

.from,
.to {
    color: #B8B8C0;
    text-transform: capitalize;
}

.min-value,
.max-value {
    height: 14px;
}

.slider-wrapper {
    position: relative;
}



.slider-labels {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;

    font-size: 12px;
    font-weight: 700;
    line-height: 14.06px;
    text-align: left;
    color: white;

}
</style>