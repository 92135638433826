<template>
  <main class="main page-inside payment-result-page">
    <div class="section payment-section">
      <div class="page-inside-top">
        <div class="wrapper">
          <div class="title big">
            {{ $t(`Thank you - your payment was successful. You will shortly receive a confirmation to your e-mail. In
            case of any questions, please contact us at `) }} {{ this.footerSupportEmail }}
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PaymentSuccessPage',
  components: {
  },
  props: [
    'footerSupportEmail',
  ],
  created() {
  },
  async mounted() {
    // await this.fetchActiveDiscount(this.currencyCode);
    // if (this.hasActiveDiscount) {
    //   this.openDiscountModal();
    // }
  },
  data: function () {
    return {

    }
  },
  computed: {
    // ...mapGetters('discount', ['hasActiveDiscount']),
    ...mapGetters('app', ['currencyCode']),
  },
  methods: {
    // ...mapActions('discount', ['fetchActiveDiscount', 'openDiscountModal']),
  },
}
</script>

<style lang="scss" scoped>
.main {
  min-height: 100%;

}

.title {
  text-align: center;
  margin-top: 60px;
}
</style>