<template>
  <div class="modal product-modal">
    <div
      class="overlay"
      @click="closeProductModal"
    />
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <img
              class="close"
              src="./../assets/img/close.svg"
              @click="closeProductModal"
            >
            <div class="preview">
              <img
                :src="getImageUrl(productPageItem)"
                class="img"
              >
            </div>
            <div class="text">
              <div class="left">
                <div class="desc">
                  {{ productPageItem.title || productPageItem.name }}
                </div>
                <div
                  class="desc desc-quality"
                  v-if="productPageItem.quality"
                >
                  <b>{{ productPageItem.quality }}</b>
                </div>
                <div
                  class="desc"
                  v-if="productPageItem.description"
                >
                  {{ productPageItem.description }}
                </div>
                <div
                  class="desc"
                  v-html="productPageItem.content_desc"
                  v-if="productPageItem.content_desc"
                ></div>
              </div>
              <div class="right">
                <div class="title small price">
                  <span class="currency">{{ currencySymbol }}</span>
                  {{ productPageItem.price }}
                </div>
              </div>
            </div>
            <button
              class="button blue"
              @click="addToCart(productPageItem)"
            >
              <div
                v-if="loaderIsVisible"
                class="loader-container"
              >
                <div class="loader"></div>
              </div>
              <span v-if="!loaderIsVisible">{{ $t('Add to cart') }}</span>
            </button>
            <transition>
              <div
                v-if="chosenItem"
                class="desc green"
              >
                {{ $t('Added') }}
              </div>
            </transition>
            <transition>
              <div
                v-if="error"
                class="error-desc desc red"
              >
                {{ error }}
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductModal',
  props: {
    productPageItem: {
      type: Object,
      required: true
    },
    currencySymbol: {
      type: String,
      required: true
    },
    chosenItem: {
      type: String,
      required: true
    },
    error: {
      type: String,
      required: true
    },
    loaderIsVisible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      imgDomain: ''
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    closeProductModal() {
      this.$emit('closeProductModal');
    },
    addToCart(item) {
      this.$emit('addToCart', item.id, item.item_type);
    },
    getImageUrl(item) {
      if (item.item_type === 'pack') {
        return item.image_url;
      } else {
        return this.imgDomain + item.img_url;
      }
    },
    getItemType(item) {
      if (item.item_type === 'pack') {
        return 'pack';
      } else {
        return 'item';
      }
    }
  }
}
</script>

<style scoped lang="scss">
.product-modal .preview {
  position: relative;
  max-width: 512px;
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.product-modal .preview img {
  position: relative;
  z-index: 1;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  -o-object-fit: contain;
  object-fit: contain;
  width: auto;
  height: auto;
}

.product-modal .button {
  width: 100%;
  max-width: 100%;
  margin-top: 24px;
}
</style>