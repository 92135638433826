<template>
    <div class="transaction-history">
        <table>
            <thead>
                <tr>
                    <th>{{ $t(`DATE`) }}</th>
                    <th>{{ $t(`TYPE`) }}</th>
                    <th>{{ $t(`TOTAL`) }}</th>
                    <th class="status-row">{{ $t(`STATUS`) }}</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(transaction) in transactions"
                    :key="transaction.id"
                >
                    <td>{{ formatDate(transaction.created_at) }}</td>
                    <td>{{ $t(`Top up balance`) }}</td>
                    <td class="amount-row">{{ formatCurrency(transaction.sum, transaction?.currency?.symbol) }}</td>
                    <td class="status-row">
                        <span
                            class="status-badge"
                            :class="transaction.status.toLowerCase()"
                        >
                            {{ transaction.status }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: 'TransactionHistory',
    data() {
        return {
            transactions: [],
        };
    },
    methods: {
        formatDate(timestamp) {
            return moment.unix(timestamp).format('DD.MM.YYYY HH:mm');
        },
        formatCurrency(amount, currency) {
            return `${currency}${parseFloat(amount).toFixed(2)}`;
        },
        fetchTransactionHistory() {
            this.$http
                .get(`${process.env.VUE_APP_API}deposit/history`)
                .then((res) => {
                    this.transactions = res.data.payload;
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        this.$emit('open-sign-in');
                    }
                });
        },
    },
    created() {
        this.fetchTransactionHistory();
    },
};
</script>

<style scoped>
.transaction-history {
    padding: 24px 12px;
}

table {
    overflow-x: auto;
    width: 100%;
    max-width: 871px;
    border-collapse: collapse;
    display: grid;
    /* Enable grid layout */
    grid-template-columns: 1.5fr 2.5fr 2fr 1fr;
    /* Define column widths using fr units */
}

thead,
tbody,
tr {
    display: contents;
    /* Make table rows and cells grid items */
}

th,
td {
    padding: 20px;
    /* Default padding */
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    border-bottom: 1px solid #262634;
    display: flex;
    align-items: center;
}

th {
    border-bottom: none;
}

.amount-row {
    font-weight: 700;
}


th {
    font-weight: 700;
    color: #B8B8C0;
    background: #09101F;
    padding: 22px 20px 22px;
    line-height: 19px;
}

td {
    font-weight: 400;
    color: #fff;
    padding: 18px 20px 18px;
    text-wrap: nowrap;
}

/* Special padding for the first row */
tbody tr:first-of-type td {
    /* padding-top: 20px; */
}

td:hover,
td:hover~td {
    background-color: rgba(255, 255, 255, 0.1);
}

/* Optional: Add this rule to highlight the entire row */
tr:hover td {
    background-color: rgba(255, 255, 255, 0.1);
}

.status-row {
    justify-content: center;
}


.status-badge {
    display: inline-block;
    padding: 7px 12px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    /* Center text within badge */
    text-transform: uppercase;
}

.status-badge.success {
    background: #0ACB671A;
    border: 1px solid #0ACB67;
    color: #0ACB67;
}

.status-badge.pending {
    background: rgba(255, 191, 0, 0.1);
    border: 1px solid #FFBF00;
    color: #FFBF00;
}

.status-badge.error {
    background: rgba(255, 0, 0, 0.1);
    border: 1px solid #FF0000;
    color: #FF0000;
}

/* Responsiveness - Example using media queries */
@media (max-width: 768px) {}
</style>