<template>
  <div class="modal order-modal">
    <div
      class="overlay"
      @click="$emit('closeOrderModal')"
    />
    <div class="wrapper">
      <div class="container">
        <img
          src="./../assets/img/close.svg"
          @click="$emit('closeOrderModal')"
          class="close"
        />
        <div class="title small">{{ $t("Payment Method") }}</div>
        <div class="button-list-container">
          <div
            class="button-list"
            v-if="paymentMethods && paymentMethods.length"
          >
            <button
              v-for="(item, i) in paymentMethods"
              class="img-btn"
              @click="submit(item.code)"
              :key="i"
            >
              <img
                :src="item.image"
                class="img"
              />
              {{ item.title }}
            </button>
          </div>
          <Transition>
            <div
              class="desc red"
              v-if="error"
            >{{ error }}</div>
          </Transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OrderModal',
  props: {
    paymentMethods: {
      type: Array,
      required: true
    },
    error: {
      type: String,
      required: true
    },
    orderData: {
      type: Object,
      required: true
    }

  },
  data: function () {
    return {
    }
  },
  methods: {
    submit(type) {
      this.$emit('orderSubmit', this.orderData, type)
    },
  },
  mounted() {
  }
}
</script>
<style lang="scss" scoped>
.order-modal .wrapper .container {
  max-height: calc(100vh - 32px);
  display: block;
}

.order-modal {
  z-index: 1100;
  padding: 16px;

  .wrapper {
    max-width: 1300px;
  }

  .button {
    width: 190px;
    margin: 0;
  }

  .img-btn-container {
    width: calc((100% - 40px) / 3);
  }

  .img-btn {
    line-height: 0;
    padding: 0;
    background: transparent;
    width: auto;
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #fff;
    text-align: left;
    padding: 6px 16px 6px 6px;
    transition: .3s;
    border-radius: 24px;
    word-break: break-word;

    .img {
      height: 100%;
    }

    &:hover {
      background: #1C1A24;
    }
  }

  .button-list-container {
    padding: 50px;

    &>.desc {
      text-align: center;
    }
  }

  .button-list {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
    gap: 10px 20px;
  }
}

.order-modal .title {
  text-align: center;
  padding-top: 50px;
}
</style>