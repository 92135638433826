<template>
    <section class="section hero-section">
        <img
            src="./../assets/img/hero-section.png"
            alt="Hero Section"
            class="hero-image"
        >
        <img
            src="@/assets/img/explore.png"
            class="explore-image"
            alt=""
        >
        <img
            src="@/assets/img/hero-left-arrow.png"
            class="hero-left-arrow-image"
            alt=""
        >
        <img
            src="@/assets/img/hero-right-arrow.png"
            class="hero-right-arrow-image"
            alt=""
        >
        <div class="wrapper">
            <div class="center">
                <div class="title big">
                    {{ $t('Explore CS2 Skin: ') }}
                </div>
                <div class="desc">
                    {{ $t('Find the Perfect Skin to Match Your Style')
                    }}
                </div>

                <button
                    v-if="!isAuth"
                    class="sign-in-button"
                    @click="$emit('openSignUp')"
                >
                    {{ $t('Buy now') }}
                </button>

                <router-link
                    v-if="isAuth"
                    to="/products/all"
                    class="sign-in-button"
                >
                    {{ $t('Buy now') }}
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'HeroSection',
    props: {
        isAuth: {
            type: Boolean,
            required: true
        }
    }
}
</script>

<style scoped lang="scss">
.hero-section {

    position: relative;

    .wrapper {
        padding: 536px 0 68px 0;
        display: flex;
        align-items: center;
        position: relative;
    }

    .center {
        max-width: 654px;
        margin: 0 auto;
        text-align: center;

        .desc {
            font-family: 'Montserrat', sans-serif;
            margin-top: 10px;
            font-size: 32px;
            line-height: 39px;
            font-weight: 400;
        }

        .sign-in-button {
            margin-top: 24px;
        }
    }


    .hero-image {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 52%;
        transform: translateX(-50%);
    }

    .explore-image {
        position: absolute;
        z-index: 0;
        top: 62px;
        left: 50%;
        transform: translateX(-50%);
    }

    .hero-left-arrow-image {
        position: absolute;
        z-index: 0;
        top: 536px;
        left: 0;
    }

    .hero-right-arrow-image {

        position: absolute;
        z-index: 0;
        top: 536px;
        right: 0;
    }
}


@media screen and (max-width: 1000px) {
    .hero-section .hero-image {
        display: none;
    }

    .hero-section .wrapper {
        flex-direction: column;
        padding: 50px 0;

    }

    .hero-section .center {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .hero-section .center .desc,
    .hero-section .center .title.big {
        text-align: center;
    }
}


@media (max-width: 768px) {
    .hero-section .wrapper {
        align-items: flex-start;
    }
}
</style>