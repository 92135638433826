
import { createStore } from 'vuex'

import app from './modules/app'
// import discount from './modules/discount'
import user from './modules/user'
import modals from './modules/modals'
import cart from './modules/cart'


const debug = process.env.NODE_ENV !== 'production'

const store = createStore({
    modules: {
        app,
        // discount,
        user,
        modals,
        cart,
    },
    strict: debug,
})

export default store