<template>
  <div class="product-card">
    <div
      v-if="hasDiscount"
      class="badge discount-badge"
    >
      <img
        src="./../assets/img/discount-icon.svg"
        alt="Discount"
      />
    </div>
    <div
      v-else-if="isHotItem"
      class="badge hot-item-badge"
    >
      <img
        src="./../assets/img/flame.svg"
        alt="Hot Item"
      />
    </div>
    <div
      v-else-if="lastSale"
      class="badge last-sale-badge"
    >
      <img
        src="./../assets/img/invoice.png"
        alt="Last Sale Item"
      />
    </div>
    <h3 class="product-title">{{ getTitle(item) }}</h3>
    <div class="image-container">
      <img
        :src="getImageUrl(item)"
        :alt="getTitle(item)"
        class="product-image"
      />
    </div>
    <div class="price-buy-container">
      <div class="price-container">
        <span
          v-if="hasDiscount"
          class="old-price"
        >{{ item.old_price }} {{ currencySymbol }}</span>
        <span class="current-price">{{ item.price }} {{ currencySymbol }}</span>
      </div>
      <button
        class="product-card-button"
        @click="buyItem(item)"
      >
        {{ $t(`Buy`) }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductCard',
  props: {
    item: {
      type: Object,
      required: true
    },
    currencySymbol: {
      type: String,
      required: true
    },
    isHotItem: {
      type: Boolean,
      default: false
    },
    lastSale: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      imgDomain: ''
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN
  },
  computed: {
    hasDiscount() {
      return this.item && this.item.old_price && this.item.old_price !== '0.00';
    },
  },
  methods: {
    getTitle(item) {
      return item.title || item.name;
    },
    getImageUrl(item) {
      return item.img_url ? `${this.imgDomain}${item.img_url}` : '';
    },
    buyItem(item) {
      // this.$emit('buy', this.item);
      this.$emit('goToProduct', item);
    }
  }
};
</script>

<style lang="scss" scoped>
.product-card {
  border: 1px solid #161C2A;
  padding: 16px;
  border-radius: 16px;
  position: relative;
  background: #09101F;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
  z-index: 1;

  &::before {
    content: '';
    z-index: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 16px;
    border: 2px solid transparent;
    background: linear-gradient(180deg, #FFE416 0%, #00B330 100%) border-box;
    -webkit-mask:
      linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover {
    background: #09101F;

    &::before {
      opacity: 1;
    }
  }
}

.badge {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;

  img {
    width: 100%;
    height: 100%;
  }
}

.product-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: white;
  height: 32px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.image-container {
  width: 172px;
  height: 129px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 20px;

  .product-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

.price-buy-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
  max-width: 180px;
}

.price-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .old-price {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #8D8D8D;
    text-decoration: line-through;
  }

  .current-price {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    color: white;
  }
}
</style>