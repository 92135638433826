<template>
  <main class="main page-inside">
    <div class="main-wrapper">
      <section class="section product-list-section">


        <div class="top-block">
          <div class="wrapper">
            <div class="left-side">
              <span class="cs2-title">{{ $t(`CS 2 Market`) }}</span>
            </div>
            <div class="right-side">
              <div
                class="filter-row"
                v-if="categoriesData?.length > 0"
              >
                <CustomDropdown
                  v-for="(categoryData, index) in categoriesData"
                  :key="index"
                  :options="['all', ...categoryData.sub_categories]"
                  :placeholder="categoryData.category"
                  :initial-value="selectedCategories[categoryData.category]"
                  @option-selected="(option) => handleSubcategoryChange(categoryData.category, option)"
                  :is-filter="true"
                  class="category-dropdown"
                />
              </div>
            </div>
          </div>
          <div class="wrapper">
            <div class="left-side">
              <span class="cs2-title">{{ totalProducts }} {{ totalProducts === 1 ? 'item' : 'items' }}</span>
            </div>
            <div class="right-side">
              <div
                class="filter-row"
                v-if="categoriesData?.length > 0"
              >
                <CustomDropdown
                  :options="sortOptions"
                  :placeholder="$t('Sort by')"
                  :initial-value="selectedSortOption"
                  @option-selected="handleSortChange"
                  :is-filter="true"
                  :showSelectedValue="true"
                  class="sort-dropdown"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="wrapper filters-and-products-wrapper">
          <div class="filters-and-products">

            <div :class="['top', { active: filtersIsVisible }]">
              <div class="filters">
                <div class="filters-wrapper">

                  <div
                    class="clear-all-button"
                    @click="resetFilters"
                  >
                    {{ $t("Clear all filters") }}
                  </div>

                  <RangeSlider
                    :min-value="barMinValue"
                    :max-value="barMaxValue"
                    @update-range="updatePriceFilter"
                  />


                  <!-- <div class="filters-price-container">
                    <div
                      :class="['title small price', { active: !priceListIsVisible }]"
                      @click="priceListIsVisible = !priceListIsVisible"
                    >
                      {{ $t("Price") }}
                    </div>
                    <div :class="['select-container select-container-range', { hidden: !priceListIsVisible }]">
                      <div class="val-container">
                        <div class="val min">
                          <input
                            type="number"
                            v-model="barMinValue"
                          />
                          <div class="desc">{{ currencySymbol }}</div>
                        </div>
                        <div class="divider"></div>
                        <div class="val max">
                          <input
                            type="number"
                            v-model="barMaxValue"
                          />
                          <div class="desc">{{ currencySymbol }}</div>
                        </div>
                      </div>
                    </div>
                  </div> -->

                  <template v-for="(filter, filterName) in filters">
                    <div
                      :key="filterName"
                      class="filter-container"
                      :class="`filter-${filterName}-container`"
                      v-if="filter.options.length > 1"
                    >
                      <div
                        :class="['title small', { active: !filter.visible }]"
                        @click="toggleFilterVisibility(filterName)"
                      >
                        {{ formatFilterName(filterName) }}
                      </div>
                      <div :class="['select-container', { hidden: !filter.visible }]">
                        <label
                          class="checkbox-label"
                          v-for="(option, i) in filter.options"
                          :key="i"
                        >
                          <div class="checkbox-label-wrapper">
                            <input
                              type="radio"
                              :name="filterName"
                              :value="option"
                              v-model="filters[filterName].selected"
                            />
                            <div class="checkbox"></div>
                            <span class="title">{{ option }}</span>
                          </div>
                        </label>
                      </div>
                    </div>
                  </template>

                </div>
              </div>
            </div>

            <div :class="['products-container', { active: !filtersIsVisible }]">

              <transition name="fade">
                <div
                  class="list products"
                  v-if="productList && productList.length"
                >
                  <div
                    class="item"
                    v-for="(item, i) in productList"
                    :key="i"
                  >
                    <ProductCard
                      :chosenItem="chosenItem"
                      :item="item"
                      :currencySymbol="currencySymbol"
                      :is-listing-page="true"
                      @goToProduct="goToProduct"
                      @addToCart="addToCart"
                    />
                  </div>
                </div>

                <div
                  v-else-if="isFetchingProducts"
                  class="loading"
                >
                  {{ $t("Loading...") }}
                </div>

                <div
                  v-else
                  class="empty"
                >
                  {{ $t("No products found") }}
                  <br />
                  <div
                    class="clear-all-button"
                    @click="resetFilters"
                  >
                    {{ $t("Clear all filters") }}
                  </div>

                </div>
              </transition>

            </div>


          </div>

          <Pagination
            v-if="totalProducts"
            :total-pages="totalPages"
            v-model:per-page="perPage"
            v-model:current-page="activePage"
            @update:per-page="updatePerPage"
            @update:current-page="changePage"
          />
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import ProductCard from "../components/ProductCard.vue";
import Pagination from '../components/PaginationComponent.vue';
import { mapGetters } from "vuex";
import { debounce } from "lodash";
import RangeSlider from "@/components/RangeSlider.vue";
import { onClickOutside } from '@vueuse/core'
import CustomDropdown from "@/components/CustomDropdown.vue";

export default {
  name: "ProductListPage",
  props: {
    chosenItem: {
      type: String,
    },
    itemType: {
      type: String
    },
  },
  components: {
    Pagination,
    ProductCard,
    RangeSlider,
    CustomDropdown,
  },
  data: function () {
    return {
      isFetchingProducts: true,
      imgDomain: "",
      filtersIsVisible: true,

      isOpen: false,
      sortOptions: [
        "PRICE: HIGH TO LOW",
        "PRICE: LOW TO HIGH",
      ],
      selectedSortOption: "PRICE: HIGH TO LOW",
      sort: "desc",

      category: "",
      quality: "",
      searchQuery: "",
      totalProducts: null,

      totalPages: 1,
      perPage: 20,
      activePage: 1,
      selectedCategories: {},
      categoriesData: [],
      categoryOptions: {},
      qualityOptions: {},
      barMinValue: 0,
      barMaxValue: 9999,

      productList: {},
      priceListIsVisible: true,
      specialCategory: "",
      // selectedGame: "cs2",

      isResettingFilters: false,
      isInitialLoad: true,

      filters: {
        types: {
          options: [],
          selected: 'all',
          visible: true,
          apiParam: 'type'
        },
        qualities: {
          options: [],
          selected: 'all',
          visible: false,
          apiParam: 'quality'
        },
        heroes: {
          options: [],
          selected: 'all',
          visible: false,
          apiParam: 'hero'
        },
        exterior_names: {
          options: [],
          selected: 'all',
          visible: false,
          apiParam: 'exterior_names'
        },
        classes: {
          options: [],
          selected: 'all',
          visible: false,
          apiParam: 'item_class'
        }
      },
    };
  },
  computed: {
    ...mapGetters("app", ["currencySymbol", "currencyCode"]),
    selectedFilters() {
      return Object.values(this.filters).map(filter => filter.selected);
    }
  },
  watch: {
    currencyCode: function () {
      this.filterProducts();
    },
    barMinValue: function () {
      if (!this.isResettingFilters && !this.isInitialLoad) {
        this.activePage = 1;
        this.debouncedFilterProducts();
      }
    },
    barMaxValue: function () {
      if (!this.isResettingFilters && !this.isInitialLoad) {
        this.activePage = 1;
        this.debouncedFilterProducts();
      }
    },
    sort() {
      if (!this.isResettingFilters && !this.isInitialLoad) {
        this.activePage = 1;
        this.filterProducts();
      }
    },
    'filters.types.selected'() {
      if (!this.isResettingFilters && !this.isInitialLoad) {

        const newParams = { ...this.$route.params };
        newParams.itemType = this.filters.types.selected; // Sync with filter

        this.$router.push({
          name: 'ProductListPage',
          params: newParams,
          query: { ...this.$route.query }, // Keep existing query params
        });
      }
    },
    selectedFilters: {
      deep: true,
      handler() {
        if (!this.isResettingFilters && !this.isInitialLoad) {
          this.activePage = 1;
          this.debouncedFilterProducts();
        }
      }
    },
    $route() {
      if (!this.isResettingFilters && !this.isInitialLoad) {
        this.activePage = 1;
        if (this.$route.query.search !== this.searchQuery) {
          this.searchQuery = this.$route.query.search || '';
        }
        this.checkRouteParams();
      }
    },
  },
  created() {
    this.debouncedFilterProducts = debounce(this.filterProducts, 300);
  },

  mounted() {
    this.checkRouteParams();
    this.fetchFilterOptions();
    this.fetchCategories();

    this.$nextTick(() => {
      this.isInitialLoad = false;
    });

    onClickOutside(this.$refs.dropdownRef, () => {
      this.isOpen = false;
    });
  },
  methods: {
    handleSortChange(option) {
      switch (option) {
        case "PRICE: HIGH TO LOW":
          this.sort = "desc";
          break;
        case "PRICE: LOW TO HIGH":
          this.sort = "asc";
          break;
        default:
          this.sort = "desc";
      }
    },
    fetchCategories() {
      this.$http.get(`${process.env.VUE_APP_API}items/subcategories`)
        .then(response => {
          if (response.data.status === "OK") {
            this.categoriesData = response.data.payload;

            // Initialize selectedCategories with category names
            this.categoriesData.forEach(categoryData => {
              this.selectedCategories[categoryData.category] = categoryData.category;
            });

            this.setInitialFiltersFromRoute();
          }
        })
        .catch(error => {
          console.error("Error fetching categories:", error);
        });
    },

    setInitialFiltersFromRoute() {
      const typeParam = this.$route.params.itemType;
      if (typeParam) {
        for (const categoryData of this.categoriesData) {
          if (categoryData.sub_categories.includes(typeParam)) {
            this.filters.types.selected = typeParam;
            break;
          }
        }
      }
    },
    handleSubcategoryChange(category, subcategory) {
      // Update the selected subcategory for this category
      this.selectedCategories[category] = subcategory;

      // Reset other categories to their default state (category name)
      for (let key in this.selectedCategories) {
        if (key !== category) {
          this.selectedCategories[key] = key;
        }
      }

      // Set the search query, or clear it if 'all' is selected
      this.searchQuery = subcategory === 'all' ? '' : subcategory;

      // Create the new route object, including both params and query
      const newRouteParams = {
        name: 'ProductListPage',
        params: { itemType: category.toLowerCase() },

      };

      if (subcategory !== 'all') {
        newRouteParams.query = { search: this.searchQuery };
      }

      this.$router.push(newRouteParams);

      this.debouncedFilterProducts();
    },

    selectOption(option, sortValue) {
      this.selectedOption = option;
      this.sort = sortValue;
      this.isOpen = false;
    },
    toggleDropdown() {
      const selectElement = this.$el.querySelector('.dropdown-select');
      selectElement.click();
    },
    updatePriceFilter(range) {
      this.barMinValue = range.min;
      this.barMaxValue = range.max;
    },
    fetchFilterOptions() {
      this.$http.get(`${process.env.VUE_APP_API}items/filter-data`)
        .then((res) => {
          const data = res.data.payload;
          for (const [key, options] of Object.entries(data)) {
            if (this.filters[key]) {
              let newOptions = ['all', ...options.map((option) => option.toLowerCase())];
              this.filters[key].options = newOptions;
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching filter options:", error);
        });
    },
    toggleFilterVisibility(filterName) {
      this.filters[filterName].visible = !this.filters[filterName].visible;
    },
    formatFilterName(name) {
      return name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    },
    checkRouteParams() {
      // if (this.$route.params.type == 'cs2' || this.$route.params.type == 'tf2') {
      //   this.selectedGame = this.$route.params.type;
      // }
      if (this.$route.params.itemType) {
        this.filters['types'].selected = this.$route.params.itemType.toLowerCase();
      }

      this.searchQuery = this.$route.query.search || '';
      this.activePage = 1;
      this.debouncedFilterProducts();
    },
    resetFilters() {
      this.isResettingFilters = true;
      for (const filterName in this.filters) {
        this.filters[filterName].selected = 'all';
      }

      this.categoriesData.forEach(categoryData => {
        this.selectedCategories[categoryData.category] = categoryData.category;
      });

      this.barMinValue = 0;
      this.barMaxValue = 9999;

      this.searchQuery = '';
      this.$router.push({ name: 'ProductListPage', params: { itemType: 'all' }, query: {} });

      this.$nextTick(() => {
        this.isResettingFilters = false;
        this.filterProducts();
      });
    },
    openFilter() {
      this.filtersIsVisible = !this.filtersIsVisible;
    },
    filterProducts() {
      this.isFetchingProducts = true;
      let params = new URLSearchParams();
      // params.append('category', this.selectedGame);

      for (const [, filter] of Object.entries(this.filters)) {
        if (filter.selected && !filter.selected.startsWith('all')) {
          params.append(filter.apiParam, filter.selected);
        }
      }

      params.append('price_from', this.barMinValue);
      params.append('price_till', this.barMaxValue);
      params.append('sort', this.sort);
      params.append('page', this.activePage);
      params.append('limit', this.perPage);

      if (this.searchQuery) {
        params.append('search', this.searchQuery);
      }

      params.append('currency', this.currencyCode);

      // if (this.$route.params.itemType) {

      //   params.append('type', this.$route.params.itemType);
      // }

      this.$http.get(`${process.env.VUE_APP_API}items/list?${params.toString()}`)
        .then((res) => {
          this.productList = res.data.payload;
          this.totalProducts = res.data.meta.total;
          this.totalPages = res.data.meta.pages;
        })
        .catch((error) => {
          console.error("Error fetching products:", error);
          if (error.response && error.response.status === 401) {
            this.$parent.openSignInModal();
          }
        })
        .finally(() => {
          this.isFetchingProducts = false;
        });
    },
    changePage: function (page) {
      this.activePage = page;
      this.debouncedFilterProducts();
    },
    updatePerPage(newPerPage) {
      this.perPage = newPerPage;
      this.activePage = 1;
      this.debouncedFilterProducts();
    },
    addToCart(item) {
      this.$emit("addToCart", item);
    },
    goToProduct(item) {
      this.$emit("goToProduct", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.product-list-section {
  color: #fff;
}

.empty {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}


.section .filters-and-products-wrapper {
  max-width: 1440px;



  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

}

.top-block {
  background-repeat: no-repeat, no-repeat;

  .cs2-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;

  }

}

.top-block .wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  // padding: 33px 0px 27px 42px;
  padding: 24px 0px 18px 42px;
}

.right-side .filter-row {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}


.products-container {
  flex: 1;
  transition: .3s;
  padding: 27px 42px 24px 24px;
  background: #FFFFFF0D;

}

.products-container.active {
  width: 100%;
}

.product-list-section .top .wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.filters-and-products {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.top.active {
  flex: 0 1 310px;
  padding: 30px 32px;
  max-height: 1121px;
}

::v-deep(.sort-dropdown) {
  .dropdown-content {
    width: 100% !important;
  }

  .multi-column {
    flex-direction: column;
  }
}

.clear-all-button {
  margin-bottom: 38px;
}



.filters .title {
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;

  cursor: pointer;
  position: relative;

  &.price {


    &.active {}
  }


  &::after {
    content: '';
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%) scale(-1);
    width: 12px;
    height: 8px;
    background: url('../../src/assets/img/arrow-down.svg');
    background-size: 12px;
    background-position: center;
    background-repeat: no-repeat;
    transition: .3s;
  }

  &.active::after {
    transform: translateY(-50%);
  }
}

.filter-container {
  margin-top: 24px;
  margin-bottom: 24px;
}

.filters .select-container {
  margin-top: 24px;
  transition: .3s;
  overflow: auto;
  max-height: 305px;

}

.filters .select-container.hidden {
  max-height: 0;
  margin-top: 0;
}

.filters .select-container-reset {
  padding-bottom: 0;
  margin-top: 20px;
  display: table;
  width: 100%;

  .button {
    background: #FFE644;
    width: 100%;
    border-radius: 12px;
  }

  &::before {
    display: none;
  }
}

.filters .select-container-reset .reset {
  justify-content: center;
  width: 126px;
}

.filters .select-container-range {
  max-height: 67px;
}

.product-list-section .checkbox-label .title {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding: 0;
  text-transform: capitalize;

  &::after {
    display: none;
  }
}

.filters-wrapper>div {
  position: relative;
}

.filters-price-container {
  margin-top: 37px;
  margin-bottom: 34px;
}

.list.products {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(208px, 1fr));
  justify-content: center;
  gap: 6px;
}

@media screen and (max-width: 768px) {

  .top.active {
    flex: 1 1 100vw;
    max-height: 400px;
    overflow-y: auto;
  }

  .products-container {
    padding: 27px 24px;
  }

  .filters .select-container {
    max-height: 900px;
  }

  .page .top-block .wrapper {
    padding: 30px 32px;
  }

  .section .filters-and-products-wrapper {}

  .filters-and-products {
    order: 2;
  }
}
</style>