// store/modules/modals.js
export default {
  namespaced: true,
  state: {
    depositModalIsVisible: false,
    cartModalIsVisible: false,
    topupModalIsVisible: false,
    error: "",
  },
  getters: {
    depositModalIsVisible: (state) => state.depositModalIsVisible,
    cartModalIsVisible: (state) => state.cartModalIsVisible,
    topupModalIsVisible: (state) => state.topupModalIsVisible,
    error: (state) => state.error,
  },
  mutations: {
    openDepositModal(state) {
      state.depositModalIsVisible = true;
      document.body.style.overflow = "hidden";
    },
    closeDepositModal(state) {
      state.depositModalIsVisible = false;
      document.body.style.overflow = "auto";
    },
    openCartModal(state) {
      state.cartModalIsVisible = true;
      document.body.style.overflow = "hidden";
    },
    closeCartModal(state) {
      state.cartModalIsVisible = false;
      document.body.style.overflow = "auto";
    },
    openTopupModal(state) {
      state.topupModalIsVisible = true;
      document.body.style.overflow = "hidden";
    },
    closeTopupModal(state) {
      state.topupModalIsVisible = false;
      document.body.style.overflow = "auto";
    },
  },
  actions: {
    openDepositModal({ commit }) {
      commit("openDepositModal");
    },
    closeDepositModal({ commit }) {
      commit("closeDepositModal");
    },
    openCartModal({ commit }) {
      commit("openCartModal");
    },
    closeCartModal({ commit }) {
      commit("closeCartModal");
    },
    openTopupModal({ commit }) {
      commit("openTopupModal");
    },
    closeTopupModal({ commit }) {
      commit("closeTopupModal");
    },
  },
};