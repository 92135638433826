<template>
  <main class="page-inside page-not-found">
    <div class="section page-not-found-section">
      <div class="title big">
        404
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'PageNotFound',
  components: {
  },
  props: [],
  data: function () {
    return {

    }
  },
  methods: {

  }
}
</script>

<style scoped>
.title.big {
  font-size: 48px;
  font-weight: 400;
  text-align: center;
  color: #FFFFFF;
}
</style>