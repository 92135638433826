<template>
    <section
        class="section"
        :style="containerStyle"
    >
        <div class="wrapper">
            <div class="breadcrumbs">
                <router-link
                    to="/"
                    class="breadcrumb-link"
                >
                    {{ $t(`Home`) }}
                </router-link>
                <template v-if="crumbs.length">
                    <div
                        v-for="(crumb, index) in crumbs"
                        :key="index"
                        class="breadcrumb-content"
                    >
                        <span class="breadcrumb-separator">/</span>
                        <router-link
                            :to="crumb.link"
                            class="breadcrumb-link"
                        >
                            {{ crumb.text }}
                        </router-link>
                    </div>
                </template>
            </div>
        </div>
    </section>
</template>

<script>
/* eslint-disable vue/multi-word-component-names */
export default {
    name: 'Breadcrumbs',
    props: {
        crumbs: {
            type: Array,
            default: () => [],
        },
        containerStyle: {
            type: Object,
            default: () => ({
                padding: '16px',
                // background: 'radial-gradient(34.54% 24.18% at 50% 6.8%, #2E2C41 0%, #181824 100%)'
            }),
        },
    },
    mounted() {
    }
};
</script>

<style scoped lang="scss">
.section .wrapper {
    padding: 0 !important;
}

.breadcrumbs {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    text-transform: capitalize;
    flex-wrap: wrap;
    gap: 4px;

    .breadcrumb-link {
        color: #B8B8C0;
        font-size: 12px;
        font-weight: 700;
        line-height: 1;
        display: inline-flex;
        align-items: center;
        padding: 0;



        &:hover {
            text-decoration: underline;
        }
    }

    .breadcrumb-content:last-of-type .breadcrumb-link {
        color: #6462FF;
    }

    .breadcrumb-content {

        display: inline-flex;
        align-items: center;
    }

    .breadcrumb-separator {
        color: #686A7F;

        display: inline-flex;
        align-items: center;
        height: 14px;

        &:not(:last-child) {
            margin-right: 4px;
        }
    }
}
</style>