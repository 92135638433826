<template>
  <header class="header">
    <div :class="['wrapper', { 'is-auth': isAuth, 'not-home': this.$route.path !== '/' }]">
      <div class="left">
        <router-link
          to="/"
          class="logo"
        >
          <img
            src="./../assets/img/logo.svg"
            class="img"
          />
        </router-link>

        <template v-if="isAuth">
          <router-link
            :to="{ name: 'ProductListPage' }"
            class="header-button"
          >
            {{ $t('Market') }}
          </router-link>

          <div class="search-bar">
            <img
              @click="handleEnterKey"
              src="@/assets/img/search-icon.svg"
              alt="Search"
              class="search-icon"
            >
            <input
              type="text"
              v-model="searchTerm"
              @keydown.enter="handleEnterKey"
              :placeholder="$t('Search')"
              class="search-input"
            />
            <img
              v-if="searchTerm"
              @click="handleClearSearch"
              src="@/assets/img/close.svg"
              class="clear-icon"
              alt="Clear Search"
            >
          </div>
          <!-- <SearchResults
              :results="searchResults"
              :searchQuery="searchTerm"
              :searchIsLoading="searchIsLoading"
              v-if="showSearchResults"
            /> -->
        </template>

      </div>
      <div class="right">

        <!-- Language Selector -->
        <!-- <div
          v-if="languages.length > 1"
          class="select-container language-select-container"
        >
          <Select2
            v-model="selectedLanguage"
            :options="languageOptions"
            @select="handleLanguageChange"
            class="select2 single language-select"
          />
        </div> -->
        <!-- User Authentication and Cart Links -->

        <CustomDropdown
          v-if="languageOptions && languageOptions.length > 1"
          :options="languageOptions"
          :placeholder="$t('Select Language')"
          :isCurrency="true"
          :initialValue="initialLanguageOption"
          @option-selected="handleLanguageChange"
        >
          <template #default="{ selectedOption }">
            <span>{{ selectedOption || $t('Select Language') }}</span>
          </template>
        </CustomDropdown>

        <template v-if="isAuth">
          <router-link
            to="/profile/settings"
            class="wallet-button"
          >
            <img
              src="./../assets/img/wallet.svg"
              alt="Wallet"
              class="wallet-icon"
            >
            <span class="balance">{{ userBalance?.toFixed(2) }} {{ currencySymbol }}</span>
            <img
              src="./../assets/img/plus.svg"
              alt="Plus"
              class="plus-icon"
            >
          </router-link>

          <button
            @click='openCartModal'
            class="icon cart-icon"
          >
            <img
              src="./../assets/img/cart.svg"
              class="img"
            />
            <div
              v-if="cartContents.length"
              class="indicator"
            >{{ cartContents.length }}</div>
          </button>
          <router-link
            to="/profile/settings"
            class="icon profile-icon"
          >
            <img
              src="./../assets/img/user.svg"
              class="img"
            />
          </router-link>
        </template>

        <template v-else>
          <router-link
            :to="{ name: 'ProductListPage' }"
            class="header-button"
          >
            {{ $t('Market') }}
          </router-link>
          <button
            @click="openSignIn"
            class="sign-in-up-button"
          >
            {{ $t('Sign up / Sign in') }}
          </button>
        </template>

      </div>
    </div>
  </header>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import CustomDropdown from "./CustomDropdown.vue";

export default {
  name: 'HeaderComponent',
  components: {
    CustomDropdown
  },
  props: {
    isAuth: { type: Boolean, required: true },
    cartContents: { type: Array, required: true }
  },
  data() {
    return {
      // selectedCurrency: null,
      selectedLanguage: null,
      searchTerm: ""
    };
  },
  computed: {
    ...mapState('app', ['currencies', 'languages', 'currentLanguage', 'currency']),
    ...mapGetters('app', ['currencySymbol']),
    ...mapGetters('user', ['userBalance']),
    initialLanguageOption() {
      const currentLang = this.languages.find(lang => lang.id == this.currentLanguage);
      return currentLang ? currentLang.title : null;
    },
    languageOptions() {
      return this.languages.map(lang => lang.title);
    },
  },
  watch: {
    // currency(newCurrency) {
    //   this.selectedCurrency = newCurrency?.code;
    // },
    currentLanguage(newLang) {
      this.selectedLanguage = newLang;
    },
    '$route.query.search': {
      handler(newSearchTerm) {
        this.searchTerm = newSearchTerm;
      },
      immediate: true
    }
  },
  mounted() {
    this.selectedLanguage = this.initialLanguageOption;
    // this.selectedCurrency = this.currency?.code;
    this.selectedLanguage = this.currentLanguage;
  },
  methods: {
    ...mapActions('app', ['changeLocale', 'changeCurrency']),
    ...mapActions('modals', ['openCartModal', 'closeCartModal']),
    handleClearSearch() {
      this.searchTerm = '';
      if (this.$route.name === 'ProductListPage') {
        this.handleEnterKey();
      }
    },
    handleEnterKey() {
      const newQuery = this.searchTerm && this.searchTerm.trim() ? { search: this.searchTerm } : {};

      this.$router.push({
        name: 'ProductListPage',
        params: { itemType: this.$route.params.itemType ? this.$route.params.itemType : 'all' },
        query: newQuery,
      });
    },
    handleLanguageChange(option) {
      const selectedLang = this.languages.find(lang => lang.title === option);
      if (selectedLang) {
        this.changeLocale(selectedLang.id);
      }
    },
    openSignIn() {
      this.$emit('openSignIn');
    },
    openSignUp() {
      this.$emit('openSignUp');
    }
  }
};
</script>
<style lang="scss" scoped>
.header {
  height: 76px;
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
  margin: 0 auto;
  max-width: 1280px;
}

.wrapper.not-home::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  max-width: 1280px;
  left: 50%;
  transform: translateX(-50%);
  height: 1px;
  background-color: #212735;
  z-index: -1;
}

.wrapper.is-auth.not-home::after {
  max-width: calc(100% - 32px);
}

.wrapper.is-auth {
  max-width: 100%;
}

.logo .img {
  width: 130px;
}

.header .right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 14px;
}

.header .left {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 14px;
}

.left .search-bar {
  flex: 0 1 481px;
  position: relative;
}

.left .search-bar .clear-icon {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;

  cursor: pointer;
}

.left .search-icon {
  background-image: url('@/assets/img/search-icon.svg');
  background-position: 20px 14px;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 22px;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.left .search-input {
  width: 100%;
  padding: 14px 14px 14px 50px;
}


.header .right .wallet-button {
  font-family: 'Open Sans', sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 139px;
  height: 46px;
  border-radius: 23px;
  background: #212735;
  padding: 0 14px;
  cursor: pointer;
  text-decoration: none;
  color: inherit;

  .wallet-icon {
    width: 18px;
    height: 18px;
  }

  .balance {
    font-size: 14px;
    color: #fff;
    font-weight: 700;
  }

  .plus-icon {
    width: 12px;
    height: 12px;
  }
}

.cart-icon,
.profile-icon {
  width: 46px;
  height: 46px;
  border-radius: 23px;
  background: #212735;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.icon .indicator {
  color: #fff;
  position: absolute;
  bottom: 4px;
  right: 4px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

.header .desc {
  transition: .3s;
  cursor: pointer;
}

.header .desc:hover {
  opacity: .7;
}

.header .nav {
  display: flex;
  align-items: center;
  position: relative;
  gap: 40px;
}

.header .nav__item {
  text-align: center;
}


.router-link-active,
.active {
  color: #fff;
}

@media screen and (max-width: 768px) {

  .header {
    padding-right: 60px;
  }

  .mob {
    display: block;
  }

  .header .center {
    display: none;
  }

  .header .right {
    display: none;
  }

  .logo .img {
    width: 115px;
  }
}


@media screen and (max-width: 480px) {

  .search-bar {
    display: none;
  }
}
</style>