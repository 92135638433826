import axios from "@/axios";
export default {
  namespaced: true,
  state: {
    userData: {
      email: "",
      name: "",
      surname: "",
      phone: "",
      phoneCountryCode: null,
      address: "",
      country: "",
      city: "",
      zip: "",
      balance: 0,
      createdAt: null,
      steamId: "",
      steamProfileUrl: null,
      communityVisibilityState: null,
      avatarMedium: "",
      status: "",
    },
    error: null,
  },
  getters: {
    userData: (state) => state.userData,
    userBalance: (state) => state.userData?.balance,
  },
  mutations: {
    setUserData(state, userData) {
      state.userData = userData;
    },
    setError(state, error) {
      state.error = error;
    },
  },
  actions: {
    async fetchUserData({ commit, rootGetters }) {
      const currencyCode = rootGetters['app/currencyCode'];
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}user/profile?currency=${currencyCode}`
        );
        if (response.status === 200 && response.data) {
          const userData = {
            email: response.data.email,
            name: response.data.name,
            surname: response.data.surname,
            phone: response.data.phone,
            phoneCountryCode: response.data.phone_country_code,
            address: response.data.address,
            country: response.data.country,
            city: response.data.city,
            zip: response.data.zip,
            balance: response.data.balance,
            createdAt: response.data.created_at,
            steamId: response.data.steamId,
            steamProfileUrl: response.data.steam_profile_url,
            communityVisibilityState: response.data.community_visibility_state,
            avatarMedium: response.data.avatar_medium,
            status: response.data.status,
          };
          commit("setUserData", userData);
          return Promise.resolve(response.data);
        } else {
          commit("setError", "Failed to fetch user data");
          return Promise.reject("Failed to fetch user data");
        }
      } catch (error) {
        commit("setError", error.message);
        return Promise.reject(error.message);
      }
    },
  },
};
