<template>
  <div class="menu">
    <div class="link-container">
      <ul class="nav">
        <li class="nav__item">
          <router-link
            class="desc"
            to="/"
          >
            <b>{{ $t("Home") }}</b>
          </router-link>
        </li>
        <li class="nav__item">
          <router-link
            class="desc"
            to="/products/all"
          >
            <b>{{ $t("Market") }}</b>
          </router-link>
        </li>
        <!-- <li class="nav__item">
          <router-link
            class="desc"
            to="/support"
          ><b>{{ $t('Support') }}</b></router-link>
        </li> -->
      </ul>

      <!-- Currency Selector -->
      <!-- <div
        v-if="currencyOptions.length > 1"
        class="select-container"
      >
        <Select2
          v-model="selectedCurrency"
          class="select2 single currency-select"
          :options="currencyOptions"
          @select="handleCurrencyChange"
        />
      </div> -->

      <!-- Language Selector -->
      <div
        v-if="languageOptions.length > 1"
        class="select-container language-select-container"
      >
        <Select2
          v-model="selectedLanguage"
          class="select2 single language-select"
          :options="languageOptions"
          @select="handleLanguageChange"
        />
      </div>

      <!-- User Authentication and Cart Links -->
      <button
        @click="handleCartOpen"
        v-if="isAuth"
        class="icon"
      >
        <img
          src="./../assets/img/cart.svg"
          class="img"
        />
        <div
          v-if="cartContents.length"
          class="indicator"
        >
          {{ cartContents.length }}
        </div>
      </button>
      <router-link
        to="/profile/settings"
        class="icon"
        v-if="isAuth"
      >
        <img
          src="./../assets/img/user.svg"
          class="img"
        />
      </router-link>
      <button
        v-if="!isAuth"
        class="button bordered"
        @click="openSignIn"
      >
        {{ $t("Sign In") }}
      </button>
      <button
        v-if="!isAuth"
        class="button"
        @click="openSignUp"
      >
        {{ $t("Sign Up") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Select2 from "vue3-select2-component";

export default {
  name: "MobMenu",
  components: {
    Select2,
  },
  props: {
    isAuth: {
      type: Boolean,
      required: true,
    },
    cartContents: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 768) {
        this.$emit('closeMenu');
      }
    });
  },
  computed: {
    ...mapState('app', ['currencies', 'languages', 'currentLanguage', 'currency']),

    currencyOptions() {
      if (!this.currencies) return [];
      return this.currencies.map(({ code, symbol }) => ({
        id: code,
        text: `${code} (${symbol})`
      }));
    },

    languageOptions() {
      if (!this.languages) return [];
      return this.languages.map(({ id, title }) => ({
        id,
        text: title
      }));
    },

    selectedCurrency: {
      get() {
        return this.currency?.code;
      },
      set(currencyCode) {
        this.changeCurrency(currencyCode);
      },
    },

    selectedLanguage: {
      get() {
        return this.currentLanguage;
      },
      set(langId) {
        this.changeLocale(langId);
      },
    },
  },
  methods: {
    ...mapActions('app', ['changeLocale', 'changeCurrency']),
    ...mapActions('modals', ['openCartModal']),
    handleCartOpen() {
      this.openCartModal();
      this.$emit('closeMenu');
    },
    handleCurrencyChange({ id }) {
      this.selectedCurrency = id;
    },

    handleLanguageChange({ id }) {
      this.selectedLanguage = id;
    },
    openSignIn() {
      this.$emit('openSignIn');
    },
    openSignUp() {
      this.$emit('openSignUp');
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background: #09101F;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indicator {
  color: #fff;
}

.icon img {
  width: 18px;
  height: 18px;
}
</style>