<template>
  <div class="modal topup-modal">
    <div
      class="overlay"
      @click="closeTopupModal"
    />
    <div class="wrapper">
      <div class="container">
        <img
          class="close"
          src="./../assets/img/close.svg"
          @click="closeTopupModal"
        />
        <h2 class="topup-title">{{ $t(`Top up balance`) }}</h2>
        <div class="payment-method">
          <div class="left-side">
            <img
              src="./../assets/img/card.svg"
              alt="Card"
            />
            <span>{{ $t(`Card`) }}</span>
          </div>
          <div class="right-side">
            <img
              src="./../assets/img/visa.svg"
              alt="Visa"
            />
            <img
              src="./../assets/img/mastercard.svg"
              alt="Mastercard"
            />
          </div>
        </div>

        <div class="top-up-bottom">
          <p class="choose-amount">{{ $t(`Choose amount:`) }}</p>
          <div class="amount-buttons">
            <button
              v-for="amount in predefinedAmounts"
              :key="amount"
              :class="['amount-button', { active: selectedAmount === amount }]"
              @click="selectAmount(amount)"
            >
              {{ amount }} {{ currencySymbol }}
            </button>
          </div>
          <p class="or">{{ $t(`or`) }}</p>
          <div class="custom-amount">
            <input
              v-model="customAmount"
              type="number"
              :placeholder="$t('Enter your amount')"
              @input="updateSelectedAmount"
            />
            <span class="currency-code">{{ currencySymbol }}</span>
          </div>
          <p class="minimum-amount">
            {{ $t(`Minimum top-up amount`) }} <span class="amount">{{ currencySymbol }} {{ minTopup }}</span>
          </p>
          <div class="topup-footer">
            <div class="you-get">
              <span class="label">{{ $t(`You get:`) }}</span>
              <span class="amount">{{ selectedAmount || 0.00 }} {{ currencySymbol }}</span>
            </div>
            <button
              class="pay-button button"
              :disabled="!selectedAmount || parseFloat(selectedAmount) <= 0"
              @click="handlePayButton"
            >
              {{ $t(`Pay`) }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <teleport to="#app">
      <DepositModal
        :depositAmount="selectedAmount"
        :depositCurrency="currencySymbol"
        :paymentType="'card'"
        v-if="depositModalIsVisible"
        @goToPage="goToPage"
      />
    </teleport>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DepositModal from './DepositModal.vue';

export default {
  name: "TopUpModal",
  props: {
    minTopup: {
      default: 0,
    }
  },
  components: {
    DepositModal
  },
  data() {
    return {
      predefinedAmounts: [15, 25, 50, 100, 200],
      selectedAmount: null,
      customAmount: ""
    };
  },
  computed: {
    ...mapGetters('app', ['currencyCode', 'currencySymbol']),
    ...mapGetters('modals', ['depositModalIsVisible']),
  },
  watch: {
    $route() {
      this.closeTopupModal();
      this.closeDepositModal();
    }
  },
  methods: {
    ...mapActions('modals', ['openDepositModal', 'closeTopupModal', 'closeDepositModal']),
    handlePayButton() {
      if (this.selectedAmount < this.minTopup) {
        this.$toast.warning(`Minimum top-up amount is ${this.minTopup} ${this.currencySymbol}`)
        return;
      }
      this.openDepositModal();
    },
    goToPage(id) {
      this.closeTopupModal();
      this.$emit('goToPage', id);
    },
    selectAmount(amount) {
      this.selectedAmount = amount;
      this.customAmount = "";
    },
    updateSelectedAmount() {
      this.selectedAmount = parseFloat(this.customAmount) || 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.topup-modal {
  .wrapper {
    max-width: 458px;
    border-radius: 12px;
    background: #161C2A;
    position: relative;
  }

  .wrapper .container {
    position: relative;
    padding: 44px 10px 32px;
    flex-direction: column;

  }

  .close {
    position: absolute;
    top: 18px;
    right: 18px;
    cursor: pointer;
  }

  .topup-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 29.26px;
    color: white;
    text-align: center;
    margin-bottom: 20px;
  }

  .payment-method {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #09101F;
    border-radius: 12px;
    padding: 0 63px;
    height: 76px;
    margin-bottom: 20px;

    .left-side {
      display: flex;
      align-items: center;
      gap: 20px;

      span {
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 19.07px;
        color: white;
      }
    }

    .right-side {
      display: flex;
      gap: 22px;
    }
  }

  .top-up-bottom {
    margin: 0 auto;
    max-width: 320px;
    width: 100%;
  }

  .choose-amount {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #B8B8C0;
    text-align: center;
    margin-bottom: 14px;
  }

  .amount-buttons {
    display: flex;
    justify-content: center;
    gap: 14px;
    margin-bottom: 22px;
    text-wrap: nowrap;

    .amount-button {
      height: 39px;
      padding: 10px 8px;
      border-radius: 12px;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      font-weight: 700;
      color: white;
      cursor: pointer;
      position: relative;
      background: transparent;

      &::before {
        content: '';
        position: absolute;
        inset: -1px;
        padding: 2px;
        border-radius: 13px;
        background: linear-gradient(180deg, #FFE416 0%, #00B330 100%);
        -webkit-mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        mask-composite: exclude;
        z-index: -1;
      }

      &::after {
        content: '';
        position: absolute;
        inset: 0;
        border-radius: 12px;
        background: rgba(0, 178, 48, 0.1);
        z-index: -2;
      }

      &.active {
        background: radial-gradient(144.28% 89.13% at 50.34% 0%, #FFE416 0%, #00B330 100%);
        box-shadow: 0px -4px 4px 0px #FFFFFF26 inset;
      }
    }
  }

  .or {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #B8B8C0;
    text-align: center;
    margin-bottom: 16px;
  }

  .custom-amount {
    position: relative;
    margin-bottom: 8px;

    input {}

    .currency-code {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      color: #B7B8C1;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
    }
  }

  .minimum-amount {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #B8B8C0;
    margin-bottom: 28px;

    .amount {
      color: white;
      font-weight: 700;
    }
  }

  .topup-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .you-get {
      display: flex;
      align-items: center;
      gap: 10px;

      .label {
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.07px;
        color: #B8B8C0;
      }

      .amount {
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 21.79px;
        color: white;
      }
    }

    .pay-button {
      max-width: 170px;
    }
  }
}
</style>