<template>
    <section class="product-carousel-section">
        <div class="wrapper">
            <div class="header">
                <div class="left">
                    <div class="prefix title big">CS2</div>
                    <h2 class="section-title title">{{ title }}</h2>
                </div>
                <router-link
                    :to="showAllLink"
                    class="see-all-button"
                >
                    {{ $t('See all') }}
                </router-link>
            </div>
        </div>

        <div class="full-width-wrapper">
            <div class="carousel-container">
                <swiper
                    ref="swiperRef"
                    :slides-per-view="'auto'"
                    :space-between="6"
                    :free-mode="true"
                >
                    <swiper-slide
                        v-for="(item, i) in products"
                        :key="i"
                    >
                        <ProductCard
                            :item="item"
                            :currencySymbol="currencySymbol"
                            @goToProduct="goToProduct"
                        />
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductCard from '@/components/ProductCard.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/free-mode';

export default {
    name: 'ProductCarousel',
    components: {
        ProductCard,
        Swiper,
        SwiperSlide,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        showAllLink: {
            type: String,
            default: '/products/all',
        },
        apiEndpoint: {
            type: String,
            required: false,
        },
        itemList: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            products: [],
            swiperRef: null,
        };
    },
    computed: {
        ...mapGetters('app', ['currencyCode', 'currencySymbol'])
    },
    mounted() {
        if (this.itemList && this.itemList.length > 0) {
            this.products = this.itemList;
        } else if (this.apiEndpoint) {
            this.fetchProducts();
        }
        this.$nextTick(() => {
            if (this.$refs.swiperRef && this.$refs.swiperRef.swiper) {
                this.$refs.swiperRef.swiper.on('init', () => {
                    this.swiperRef = this.$refs.swiperRef.swiper;
                });
            }
        });
    },
    methods: {
        fetchProducts() {
            if (!this.apiEndpoint) return;
            this.$http.get(process.env.VUE_APP_API + this.apiEndpoint + '?currency=' + this.currencyCode)
                .then((res) => {
                    this.products = res.data.payload;
                })
                .catch((error) => {
                    console.error('Error fetching products:', error);
                    if (error.response && error.response.status === 401) {
                        this.openSignInModal();
                    }
                });
        },
        goToProduct(item) {
            this.$emit('goToProduct', item);
        },
    },
};
</script>

<style scoped lang="scss">
.product-carousel-section {
    padding: 0 16px;
    color: #FFFFFF;

    .wrapper {
        max-width: 1280px;
        margin: 0 auto;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 30px;
        gap: 20px;
        text-wrap: nowrap;

        .left {
            display: flex;
            gap: 24px;
            align-items: baseline;
        }

        .prefix {
            color: #00B230;
        }

        .section-title {}

        .see-all-button {}
    }

    .full-width-wrapper {
        background: #FFFFFF0D;
        margin-left: -16px;
        margin-right: -16px;
        padding: 40px 16px;
    }

    .carousel-container {
        max-width: 1280px;
        margin: 0 auto;
    }

    :deep(.swiper) {
        width: 100%;
    }

    :deep(.swiper-slide) {
        width: 206px !important;
    }
}

@media (max-width: 768px) {
    .title.big {
        font-size: 30px;
    }

    .title {
        font-size: 20px;
    }
}
</style>
