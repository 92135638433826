<template>
    <section class="transform-your-gameplay-section section">
        <div class="wrapper">
            <div class="dark-card">
                <div class="title big">{{ $t(`TRANSFORM YOUR GAMEPLAY`) }}</div>
                <div class="desc small gray">
                    {{ $t(`with unique, high-quality CS2 skins that enhance the look of your weapons and make your arsenal truly one-of-a-kind. Our CS2 skins store offers a vast selection of styles, from rare CS2 skins to limited edition designs, allowing you to customize your weapons with a personal touch.`) }}
                </div>
            </div>
            <div class="commited-block">
                <div class="flex">
                    <div class="text">
                        <div class="title normal-weight">{{ $t(`At Skins4all.store, we’re committed to making your CS2 skin purchase fast, secure, and easy.`) }}</div>
                        <div class="desc small gray">
                            {{ $t(`With fast digital CS2 skin delivery directly to your inventory, you can start using your new skins without delay. Our CS2 skins marketplace offers a secure checkout process, so you can buy with confidence, whether you’re looking for cheap Counter-Strike skins or premium designs.`) }}
                        </div>
                    </div>
                    <div class="img-container">
                        <img
                            src="@/assets/img/gameplay-img.png"
                            class="gameplay-img"
                            alt="gameplay img"
                        >
                    </div>
                </div>
                
            </div>
        </div>
    </section>
</template>


<script>
export default {
    name: 'TransformYourGameplay',
    data() {
        return {
        }
    }
}
</script>


<style lang="scss" scoped>
.transform-your-gameplay-section {
    position: relative;

    .wrapper {
        max-width: 1020px;
        padding-bottom: 80px;
    }
    .dark-card {
        padding: 67px 16px 87px;
        background: linear-gradient(#161C2A, #09101F);
        text-align: center;

        .title {
            max-width: 490px;
            margin: 0 auto;
        }
        .desc {
            margin: 32px auto 0;
            max-width: 635px;
        }
    }
    .commited-block {
        
        .flex {
            align-items: center;
            gap: 32px;
        }
        .text {
            flex: 1;
        }
        .img-container {
            flex: 1;
        }
        .desc {
            margin-top: 40px;
            max-width: 428px;
        }
    }
}
@media screen and (max-width: 768px) {
    .transform-your-gameplay-section .commited-block {
        text-align: center;

        .desc {
            margin: 40px auto;
        }
        .img-container {
            display: none;
        }
    }
}
</style>