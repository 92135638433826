<template>
  <main class="main page-inside payment-result-page">
    <div class="section payment-section">
      <div class="page-inside-top">
        <div class="wrapper">
          <div class="title big">
            {{ $t('Unfortunately, we were unable to process your payment. Please try again later') }}
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'PaymentFailPage',
  components: {
  },
  props: [],
  data: function () {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.main {
  min-height: 100%;

}

.title {
  text-align: center;
  margin-top: 60px;
}
</style>