<template>
    <div class="order-history">
        <table>
            <thead>
                <tr>
                    <th>#</th>
                    <th>{{ $t(`DATE`) }}</th>
                    <th>{{ $t(`ITEM`) }}</th>
                    <th>{{ $t(`TOTAL`) }}</th>
                    <th class="status-row">{{ $t(`STATUS`) }}</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(order, index) in orders"
                    :key="order.id"
                >
                    <td>{{ index + 1 }}</td>
                    <td>{{ formatDate(order.created_at) }}</td>
                    <td class="item-cell">
                        <img
                            :src="imgDomain + order.img_url"
                            :alt="order.title"
                            class="item-image"
                        >
                        <div class="item-details">
                            <span class="item-type">{{ order.item_type }}</span>
                            <span
                                class="item-title"
                                :title="order?.title"
                            >{{ order.title || 'No title' }}</span>
                        </div>
                    </td>
                    <td class="amount-row">{{ formatCurrency(order.sum, order?.currency) }}</td>
                    <td class="status-row">
                        <span
                            class="status-badge"
                            :class="order.status.toLowerCase()"
                        >
                            {{ order.status }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: 'orderHistory',
    data() {
        return {
            imgDomain: '',
            orders: [],
        };
    },
    methods: {
        formatDate(timestamp) {
            return moment.unix(timestamp).format('DD.MM.YYYY HH:mm');
        },
        formatCurrency(amount, currency) {
            return `${currency} ${parseFloat(amount).toFixed(2)}`;
        },
        fetchorderHistory() {
            this.$http
                .get(`${process.env.VUE_APP_API}purchase/history`)
                .then((res) => {
                    this.orders = res.data.payload;
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        this.$emit('open-sign-in');
                    }
                });
        },
    },
    created() {
        this.fetchorderHistory();
    },
    mounted() {
        this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    },
};
</script>

<style scoped>
.order-history {
    padding: 24px 12px;
}

table {
    overflow-x: auto;
    width: 100%;
    max-width: 871px;
    border-collapse: collapse;
    display: grid;
    /* Enable grid layout */
    grid-template-columns: 0.2fr 1fr 350px 2.5fr 1.8fr;
}

thead,
tbody,
tr {
    display: contents;
    /* Make table rows and cells grid items */
}

th,
td {
    padding: 20px;
    /* Default padding */
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    border-bottom: 1px solid #262634;
    display: flex;
    align-items: center;
}

.amount-row {
    font-weight: 700;
}


th {
    font-weight: 700;
    color: #B8B8C0;
    background: #09101F;
    padding: 22px 10px 22px;
    line-height: 19px;
}

td {
    font-weight: 400;
    color: #fff;
    padding: 18px 10px 18px;
    text-wrap: nowrap;
}

th:first-child,
td:first-child {
    padding-left: 30px;
}

th:last-child,
td:last-child {
    padding-right: 40px;
}

/* Special padding for the first row */
tbody tr:first-of-type td {
    /* padding-top: 20px; */
}

td:hover,
td:hover~td {
    background-color: rgba(255, 255, 255, 0.1);
}

/* Optional: Add this rule to highlight the entire row */
tr:hover td {
    background-color: rgba(255, 255, 255, 0.1);
}

.item-cell {
    display: flex;
    align-items: center;
}

.item-image {
    /* width: 79px; */
    height: 60px;
    margin-right: 28px;
    object-fit: cover;
}

.item-details {
    display: flex;
    flex-direction: column;
}

.item-type {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #B7B8C1;
    margin-bottom: 2px;
}

.item-title {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    color: #fff;
    max-width: 215px;
    white-space: nowrap;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.status-row {
    justify-content: center;
}


.status-badge {
    display: inline-block;
    padding: 7px 12px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    /* Center text within badge */
    text-transform: uppercase;
}

.status-badge.success {
    background: #0ACB671A;
    border: 1px solid #0ACB67;
    color: #0ACB67;
}

.status-badge.processing {
    background: rgba(255, 191, 0, 0.1);
    border: 1px solid #FFBF00;
    color: #FFBF00;
}

.status-badge.error {
    background: rgba(255, 0, 0, 0.1);
    border: 1px solid #FF0000;
    color: #FF0000;
}


/* Responsiveness - Example using media queries */
@media (max-width: 768px) {}
</style>