<template>
  <div class="profile-page">
    <div class="wrapper">
      <div class="profile-columns">
        <!-- Left Column -->
        <div class="left-column">
          <!-- Profile Block -->
          <div class="profile-block">
            <h1 class="profile-title">{{ $t(`My Profile`) }}</h1>
            <div class="profile-info">
              <div class="profile-image-wrapper">
                <img
                  src="@/assets/img/user.svg"
                  alt="User"
                  class="profile-image"
                >
              </div>
              <div class="profile-details">
                <h2>{{ fullName }}</h2>
                <p>{{ user?.email }}</p>
              </div>
            </div>
          </div>

          <!-- Balance Block -->
          <div class="balance-block">
            <div class="balance-header">
              <div class="wallet-icon-wrapper">
                <img
                  src="@/assets/img/wallet.svg"
                  alt="Wallet"
                  class="wallet-icon"
                >
              </div>
              <div class="my-balance-text">{{ $t(`My balance`) }}</div>
            </div>
            <div class="balance-amount">{{ userBalance?.toFixed(2) }} {{ currencySymbol }}</div>
            <button
              class="top-up-button"
              @click="handleTopUpModal"
            >
              {{ $t(`Top up my balance`) }}
            </button>
          </div>

          <!-- Logout Button -->
          <button
            class="logout-button"
            @click="logout"
          >
            <img
              src="@/assets/img/logout.svg"
              alt="Logout"
              class="logout-icon"
            >
            {{ $t(`Log out`) }}
          </button>
        </div>

        <!-- Right Column -->
        <div class="right-column">
          <!-- Tab Navigation -->
          <div class="profile-tabs">
            <div
              v-for="tab in tabs"
              :key="tab"
              :class="['tab', { 'active': activeTab === tab }]"
              @click="changeTab(tab)"
            >
              {{ formatTabName(tab) }}
            </div>
          </div>
          <div class="tab-indicator-wrapper">
            <div
              class="tab-indicator"
              :style="{ left: tabIndicatorPosition }"
            ></div>
          </div>

          <!-- Dynamic Tab Content -->
          <component
            :is="currentTabComponent"
            :user="user"
            :orderHistory="orderHistory"
            @updateProfile="updateProfile"
            @updatePassword="updatePassword"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TransactionHistory from './tabs/TransactionHistory.vue'
import OrderHistory from './tabs/OrderHistory.vue'
import ChangePassword from './tabs/ChangePassword.vue'
import PersonalInformation from './tabs/PersonalInformation.vue'
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ProfilePage',

  components: {
    TransactionHistory,
    OrderHistory,
    ChangePassword,
    PersonalInformation
  },

  props: {
    isAuth: {
      type: Boolean,
      required: true
    },
    orderHistory: {
      type: Array,
      required: true
    },
  },

  data() {
    return {
      tabs: ['settings', 'transactions', 'order-history'],
      activeTab: 'personal-information',
      user: {
        email: '',
        name: '',
        surname: '',
        phone: '',
        phoneCountryCode: null,
        address: '',
        country: '',
        city: '',
        zip: '',
        balance: 0,
        steamId: '',
        steamProfileUrl: null,
        avatarMedium: '',
        status: '',
      },
      countryOptions: []
    }
  },

  computed: {
    ...mapGetters('app', ['currencyCode', 'currencySymbol']),
    ...mapGetters('user', ['userBalance']),
    fullName() {
      return `${this?.user?.name} ${this?.user?.surname}`.trim()
    },
    currentTabComponent() {
      switch (this.activeTab) {
        case 'details': return 'PersonalInformation'
        case 'transactions': return 'TransactionHistory'
        case 'order-history': return 'OrderHistory'
        default: return 'PersonalInformation'
      }
    },
    tabIndicatorPosition() {
      const tabIndex = this.tabs.indexOf(this.activeTab)
      return `${(tabIndex * 33.33)}%`
    }
  },

  mounted() {
    if (this.$route.params.tab) {
      this.changeTab(this.$route.params.tab)
    }
  },
  watch: {
    $route(to) {
      if (to.params.tab) {
        this.changeTab(to.params.tab)
      }
    }
  },
  methods: {
    ...mapActions('modals', ['openTopupModal']),
    changeTab(tab) {
      this.activeTab = tab
      this.$router.push({ name: this.$route.name, params: { tab } })
    },
    formatTabName(tab) {
      return tab.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
    },
    logout() {
      this.$emit('logout')
    },
    handleTopUpModal() {
      this.openTopupModal()
    },
    async fetchUserData() {
      try {
        const response = await this.$http.get(`${process.env.VUE_APP_API}user/profile?currency=${this.currencyCode}`)
        if (response.status === 200 && response.data) {
          this.user = { ...this.user, ...response.data }
        }
      } catch (error) {
        console.error('Error fetching user data:', error)
        this.$router.push({ path: '/' })
      }
    },
    async updateProfile(data) {
      try {
        await this.$http.put(process.env.VUE_APP_API + 'user/profile', data)
        this.user = { ...this.user, ...data }
      } catch (error) {
        console.error('Error updating profile:', error)
      }
    },
    async updatePassword(passwordData) {
      try {
        const data = {
          oldPassword: passwordData.currentPassword,
          newPassword: passwordData.newPassword,
          confirmPassword: passwordData.confirmPassword
        }
        await this.$http.post(process.env.VUE_APP_API + 'user/change-password', data)
        this.$message({
          message: 'Password updated successfully!',
          type: 'success'
        })
      } catch (error) {
        console.error('Error updating password:', error)
        this.$message({
          message: 'Error updating password. Please try again.',
          type: 'error'
        })
      }
    },
  },

  created() {
    this.fetchUserData()
  }
}
</script>

<style lang="scss" scoped>
.profile-page {
  width: 100%;
  padding: 40px 0;
}

.wrapper {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

.profile-columns {
  display: flex;
  gap: 20px;
}

.left-column {
  width: 451px;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.right-column {
  flex-grow: 1;
  max-width: 895px;
  background: #161C2A;
  border-radius: 12px;
  // padding: 0 20px 20px;
}

.profile-block {
  background: #161C2A;
  border-radius: 12px;
  padding: 48px;
  height: 242px;
}

.profile-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: #fff;
  margin-bottom: 40px;
}

.profile-info {
  display: flex;
  align-items: center;
  gap: 20px;
}

.profile-image-wrapper {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background: #09101F;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-image {
  width: 18px;
  height: 18px;
}

.profile-details h2 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: white;
  margin-bottom: 4px;
}

.profile-details p {
  font-size: 14px;
  line-height: 19px;
  color: #B8B8C0;
}

.balance-block {
  background: linear-gradient(180deg, rgba(255, 230, 68, 0.1) 0%, rgba(0, 179, 48, 0.1) 100%);
  border-radius: 12px;
  padding: 30px;
  height: 318px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    background: linear-gradient(180deg, #FFE644 0%, #00B230 100%);
    border-radius: 13px;
    z-index: -1;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    padding: 2px;
  }
}

.balance-header {
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;
  font-weight: 700;
}

.wallet-icon-wrapper {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background: #09101F;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wallet-icon {
  width: 18px;
  height: 18px;
}

.my-balance-text {
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  font-family: 'Open Sans', sans-serif;
}

.balance-amount {
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  color: white;
  text-align: center;
}

.top-up-button {
  background-color: transparent;
  max-width: 100%;
}

.logout-button {
  max-width: 354px;
  margin: 0 auto;
  position: relative;
}

.logout-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 23px;
  width: 14px;
  height: 14px;
}

.profile-tabs {
  display: flex;
  border-bottom: 1px solid #262634;
}

.tab {
  min-width: 185px;
  text-align: center;
  padding: 31px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #B8B8C0;
  cursor: pointer;
  position: relative;
}

.tab.active {
  color: white;

  &::before {
    content: '';
    position: absolute;
    background-image: url('@/assets/img/glow.svg');
    bottom: 0;
    width: 100%;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-position: bottom;
    background-repeat: no-repeat;
    // height: 3px;
    // background: radial-gradient(50% 50% at 50% 50%, rgba(0, 179, 48, 0.45) 0%, rgba(2, 101, 29, 0) 100%);
    // backdrop-filter: blur(12px);
    // transition: left 0.3s ease;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100px;
    left: 50%;
    transform: translateX(-50%);
    height: 3px;
    background: radial-gradient(144.28% 89.13% at 50.34% 0%, #FFE416 0%, #00B330 100%);
    box-shadow: 0px -4px 4px 0px #FFFFFF26 inset;
    border-radius: 23px;
    transition: left 0.3s ease;
  }
}

.tab-indicator-wrapper {
  position: relative;
  height: 3px;
}

.tab-indicator {}

@media (max-width: 1200px) {
  .profile-columns {
    flex-direction: column;
  }

  .left-column,
  .right-column {
    width: 100%;
    max-width: none;
  }
}

@media (max-width: 768px) {
  .profile-tabs {
    overflow-x: auto;
  }

  .tab {
    white-space: nowrap;
  }
}
</style>