<template>
  <div class="modal cart-modal">
    <div
      class="overlay"
      @click="closeCartModal"
    />
    <div class="wrapper">
      <div class="container">
        <img
          class="close"
          src="./../assets/img/close.svg"
          @click="closeCartModal"
        />
        <div class="cart-header">
          <h2 class="cart-title">Cart <span class="item-count">({{ itemCount }} item{{ itemCount !== 1 ? 's' : ''
              }})</span></h2>
        </div>
        <div class="cart-items">
          <div
            v-for="item in cartContents"
            :key="item.item_id"
            class="cart-item"
          >
            <img
              :src="imgDomain + item.item.img_url"
              :alt="item.item.title"
              class="item-image"
            />
            <div class="item-details">
              <span class="item-type">{{ item.item.type }}</span>
              <span
                class="item-title"
                :title="item?.item?.title"
                v-html="item?.item?.title"
              ></span>
            </div>
            <div class="item-price">
              <span>${{ item.item?.price?.toFixed(2) }}</span>
              <img
                @click="handleDeleteItem(item)"
                src="./../assets/img/delete.svg"
                alt="Info"
                class="delete-icon"
              />
            </div>
          </div>
        </div>
        <div
          v-if="!hasEnoughBalance"
          class="insufficient-funds"
        >
          <div class="funds-info">
            <p class="not-enough">{{ $t(`Not enough funds!`) }}</p>
            <p class="balance">Your balance: {{ currencySymbol }} {{ userBalance?.toFixed(2) }}</p>
          </div>
          <router-link
            class="top-up-button"
            to="/profile/settings"
          >
            {{ $t(`Top up`) }}
          </router-link>
        </div>
        <div class="cart-footer">
          <div class="total">
            <span class="total-label">{{ $t(`TOTAL:`) }}</span>
            <span class="total-amount">{{ currencySymbol }} {{ totalAmount.toFixed(2) }}</span>
          </div>
          <button
            class="pay-button button"
            :disabled="!hasEnoughBalance || cartContents?.length <= 0"
            @click="handleBuyButton"
          >
            {{ $t(`Pay`) }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "CartModal",
  props: {
  },
  data: function () {
    return {
      imgDomain: '',
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  watch: {
    $route() {
      this.closeCartModal();
    }
  },
  computed: {
    ...mapGetters('app', ['currencySymbol']),
    ...mapGetters('cart', ['cartContents']),
    ...mapGetters('user', ['userBalance']),
    itemCount() {
      return this.cartContents.length;
    },
    totalAmount() {
      return this.cartContents.reduce((total, item) => total + item.item.price, 0);
    },
    hasEnoughBalance() {
      return this.userBalance >= this.totalAmount;
    }
  },
  methods: {
    ...mapActions('modals', ['closeCartModal']),
    ...mapActions('cart', ['removeFromCart', 'fetchCartItems']),
    ...mapActions('user', ['fetchUserData']),
    handleBuyButton() {
      this.$http.post(process.env.VUE_APP_API + 'purchase/product', {
      })
        .then((res) => {
          if (res.data.status == "OK") {
            this.closeCartModal();
            this.fetchCartItems();
            this.fetchUserData()
            this.$toast.success("Purchase confirmed. Thanks");
            this.$router.push({ path: '/profile/order-history' });
          } else {
            this.$toast.warning(res.data?.message)
          }
        })
        .catch((res) => {
          this.$toast.error(res.response?.data?.message)
        })
    },
    handleDeleteItem(item) {
      this.removeFromCart({ ...item, id: item.item_id });
    }
  }
};
</script>

<style lang="scss" scoped>
.cart-modal {
  .wrapper {
    max-width: 458px;
    border-radius: 12px;
    background: #161C2A;
    position: relative;
  }

  .wrapper .container {
    position: relative;
    padding: 44px 10px 24px;
    flex-direction: column;
  }

  .close {
    position: absolute;
    top: 18px;
    right: 18px;
    width: 12px;
    height: 12px;
    cursor: pointer;
  }

  .cart-header {
    margin-bottom: 24px;
    margin-left: 8px;
  }

  .cart-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    color: white;
    display: flex;
    align-items: baseline;

    .item-count {
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #B8B8C0;
      margin-left: 8px;
    }
  }

  .cart-items {
    max-height: 228px;
    overflow-y: auto;
    margin-bottom: 24px;
  }

  .cart-item {
    display: flex;
    align-items: center;
    background: #09101F;
    border-radius: 12px;
    padding: 8px 18px 8px 21px;
    margin-bottom: 8px;

    .item-image {
      width: 79px;
      height: 60px;
      object-fit: cover;
      margin-right: 28px;
    }

    .item-details {
      width: 180px;
      display: flex;
      flex-direction: column;
      margin-right: 10px;

      .item-type {
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        color: #B7B8C1;
      }

      .item-title {
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        color: white;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .item-price {
      display: flex;
      align-items: center;
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 21.79px;
      color: white;

      .delete-icon {
        cursor: pointer;
        width: 16px;
        height: 16px;
        margin-left: 32px;
      }
    }
  }

  .insufficient-funds {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    text-wrap: nowrap;
    background: linear-gradient(180deg, rgba(255, 228, 22, 0.1) 0%, rgba(255, 95, 0, 0.1) 100%);
    backdrop-filter: blur(12px);
    border-radius: 12px;
    padding: 26px 36px;
    position: relative;
    margin-bottom: 24px;

    &::before {
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      background: linear-gradient(180deg, #FF5F00 0%, #FFE416 100%);
      border-radius: 13px;
      z-index: -1;
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      mask-composite: exclude;
      padding: 2px;
    }

    .funds-info {
      .not-enough {
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 19px;
        color: white;
        margin-bottom: 6px;
      }

      .balance {
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        color: white;
      }
    }

    .top-up-button {
      background: transparent
    }
  }

  .cart-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    padding-right: 12px;
    padding-left: 14px;

    .total {
      display: flex;
      align-items: center;
      gap: 23px;

      .total-label {
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 19.07px;
        color: #B8B8C0;
      }

      .total-amount {
        text-wrap: nowrap;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 21.79px;
        color: white;
      }
    }

    .pay-button {
      max-width: 170px;
    }
  }
}

@media (max-width: 600px) {
  .cart-modal .cart-item {
    width: 437px;
  }

  .insufficient-funds {
    flex-direction: column;
    align-items: center;
  }
}
</style>