<template>
  <main class="main page-inside text-page">
    <div class="section text-section">
      <div class="page-inside-top">
        <Transition>
          <div v-if="textPageTitle" class="wrapper">
            <div class="title">
              {{ textPageTitle }}
            </div>
            <div class="desc" v-html="textPageHeader" />
            <div class="desc" v-html="textPageContent" />
          </div>
        </Transition>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  name: "TextPage",
  components: {},
  props: {
    textPageTitle: {
      type: String,
      required: true,
    },
    textPageHeader: {
      type: String,
      required: true,
    },
    textPageContent: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {};
  },
  mounted() {
    this.$emit("getTextPageContent", this.$route.params.id);
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.text-page {
  padding: 60px;
}

.text-page ::v-deep(.desc) .text-section a {
  color: #00b230;
  text-decoration: underline;

  &:hover {
    opacity: 0.6;
    text-decoration: none;
  }
}

.text-page ::v-deep(.desc) p {
  margin-bottom: 15px;
  font-family: "Calibri", sans-serif;
  font-size: 12pt;
  line-height: 1.6;
  text-align: justify;
}

.text-page ::v-deep(.desc) p strong {
  font-weight: bold;
}

.text-page ::v-deep(.desc) p:last-child {
  margin-bottom: 0px;
}

.text-page ::v-deep(.desc) ul {
  list-style: disc;
  padding-left: 30px;
  margin-bottom: 15px;
}

.text-page ::v-deep(.desc) ol {
  list-style: decimal;
  padding-left: 30px;
  margin-bottom: 15px;
}

.text-page ::v-deep(.desc) li {
  margin-bottom: 10px;
}

.text-page ::v-deep(.desc) h1,
.text-page ::v-deep(.desc) h2,
.text-page ::v-deep(.desc) h3,
.text-page ::v-deep(.desc) h4,
.text-page ::v-deep(.desc) h5,
.text-page ::v-deep(.desc) h6 {
  margin: 1em 0 0.5em;
  font-weight: bold;
  color: #000;
}

.text-page ::v-deep(.desc) span {
  font-size: inherit;
  color: inherit;
}

@media (max-width: 768px) {
  .text-page ::v-deep(.desc) {
    padding: 24px;
  }
}
</style>
